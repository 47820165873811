import React, {useState} from "react";
import {Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel} from "react-accessible-accordion";
import ToggleSwitch from "../../common/ToggleSwitch";
import ConfirmationModal from "../../common/ConfirmationModal";
import { IfService } from "../../../services";
import { toast } from "react-toastify";
import IfAccordionItemPanel from "./IfAccordionItemPanel";
import { useTranslation } from "react-i18next";
import { useSelector} from "react-redux";

const MasterIfIncentive = (props) => {
    let {activeTab,element,index,tempErrors,incentive_config,preExpandedTabs,subExpandedTabs,incentive_list,loading,addMoreIncentive,removeMoreIncentive} = props;
    const { t } = useTranslation("common");
    const [areaExpandedParent, setAreaExpandedParent] = useState(false);
    const [areaExpandedChild, setAreaExpandedChild] = useState(false);
    const [deleteIncentivepopup, setDeleteIncentivepopup] = useState(false);
    const [modifyStatusPopup, setModifyStatusPopup] = useState(false);
    const [elementSelected, setElementSelected] = useState({});
    const userInfo = useSelector((state) => state.auth && state.auth.authUser && state.auth.authUser.user_data); 
    
    const showModalpopup = (value, element) => {
        if (!element.is_expired) {
            setElementSelected(element);
            if(value==="ModifyStatusPopup"){
                setModifyStatusPopup(true);
            }else{
                setDeleteIncentivepopup(true);
            }
          document.body.classList.add("overflow-hidden");
        }
    };

    const handleAddIncentive = (element) => { 
        props.showExpandedTabUuid(index);
        props.handleAddIncentive(element[0].scheme_type_id);
      };
    
    // const handleCopyIncentive = (element, data) => { 
    //     props.showExpandedTabUuid(index);
    //     props.handleCopyIncentive(data, element[0].scheme_type_id);
    // };
    
    const handleParentClicks = (e) => {
        e.stopPropagation();
        if (e.target.className.indexOf("accordion__button") !== -1) {
            if(e.target["ariaExpanded"] === "true") {
                props.showExpandedTabUuid(-1);
                setAreaExpandedParent(false);
            }else{
                props.showExpandedTabUuid(index);
                setAreaExpandedParent(true);
            }
        }

        if(e.target.attributes && e.target.attributes.accordionstatus && e.target.attributes.accordionstatus.nodeValue) {
            if(e.target.attributes.accordionstatus.nodeValue === "open") {
                props.showExpandedTabUuid(-1);
                setAreaExpandedParent(false);
            } else {
                props.showExpandedTabUuid(props.index);
                setAreaExpandedParent(true);
            }
        }
      };
    
    const handleSubParentClicks = (e, childRefUUid) => {
        e.stopPropagation(); 
        //ON TAB CLICK OPEN/CLOSE HANDLER
        if (typeof e.target.className =='string' && e.target.className.indexOf("accordion__button") !== -1) {
            if(e.target["ariaExpanded"] === "true"){
                props.showSubExpandedTabUuid(-1);
                setAreaExpandedChild(false)
            } else {
                props.showSubExpandedTabUuid(childRefUUid);
            }
        }
    
        //ON HEADING CLICK OPEN/CLOSE HANDLER
        if (e.target.attributes && e.target.attributes.accordionstatus && e.target.attributes.accordionstatus.nodeValue) {
            if (e.target.attributes.accordionstatus.nodeValue === "open") {
                props.showSubExpandedTabUuid(-1);
                setAreaExpandedChild(false)
            }else {
                props.showSubExpandedTabUuid(childRefUUid);
            }
        }
      };
    
      const handleNestedClicks = (e) => {
        e.stopPropagation();
      };

      const activeModalResponse=(response, selectedElement)=>{
        setModifyStatusPopup(false);
        if (response.response) {
            let element_selected = response.type;
            let status=(element_selected.status===2 || element_selected.status===false)?1:2;
            if (response.type.id) {
                IfService.updateStatus({id:response.type.id,status:status}).then(function (response) {
                    if (response.data.status === 200) {
                        toast.success(response.data.message);
                    }
                });
            }
            props.handleChange("status",status,true,element_selected);
        }
        document.body.classList.remove("overflow-hidden");
      }
    
    const confirmationModalResponse=(response)=>{ 
            setDeleteIncentivepopup(false);
        if (response.response) { 
            props.emptyErrors();
          if (response.type.id) {
            IfService.updateStatus({id: response.type.id,status:0}).then(function (response) {
                if (response.data.status === 200) {
                    toast.success(response.data.message);
                }
            });
            
          }
          props.confirmationModalResponse(response);
        } else {
          document.body.classList.remove("overflow-hidden");
        }
      }
    const productName=(type)=>{
        let { product_type } = props.incentive_config;
    
        let pdName = "";
        if (product_type && product_type.length) {
          let item = product_type.filter(ob=>type.includes(ob.value))
            for(let index = 0; index < item.length; index++) {
                let space = pdName ? ' ':'';
                pdName = pdName + space + item[index].value;
            }
          pdName = item.length ? pdName : "";
        }
        return pdName;
      }
    
    const expiryMonth=(data)=>{
        let { scheme_expiry_date } = data;
        let month = scheme_expiry_date ? new Date(scheme_expiry_date).toLocaleString('default', { month: 'long' }) :'';
         return month
    }
    
    return (
        <React.Fragment>
          <div className="container-fluid">
            <Accordion allowZeroExpanded="true">
              <AccordionItem
                key={index}
                uuid={index}
                onClick={handleParentClicks}
                dangerouslySetExpanded={
                  preExpandedTabs === index || areaExpandedParent
                }
              >
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h2
                      accordionstatus={
                        preExpandedTabs === index || areaExpandedParent
                          ? "open"
                          : "close"
                      }
                    >
                      {element.length && element[0].parent_name} (
                      {element.filter(String).length})
                    </h2>
                    {activeTab === 1 && userInfo.config && userInfo.config.INCENTIVE_PAGE.hasOwnProperty('ADD') && (
                      <button onClick={() => {handleAddIncentive(element);}}
                        className="btn-line"
                      >
                        {" "}
                        {t("FORM.ADD_NEW")}
                      </button>
                    )}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="sub-accordion">
                    <Accordion allowZeroExpanded="true">
                      {element.length > 0 &&
                        element.map((data, index) => (
                          <AccordionItem
                            key={"parent" + index}
                            uuid={data.id}
                            onClick={(e) =>handleSubParentClicks(e,index + "__" +data.id)}
                            dangerouslySetExpanded={
                              subExpandedTabs === index + "__" + data.id ||
                              areaExpandedChild
                            }
                          >
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <h2
                                  accordionstatus={
                                    subExpandedTabs === index + "__" + data.id
                                      ? "open"
                                      : "close"
                                  }
                                >
                                  <span className="p-xxl-r">{element.length && element[0].parent_name}</span>
                                  <span className="p-xxl-r"> {t("COMMON.SCHEME_ID")} : {data.id ? data.id : "NA"} {" "}</span>
                                  <span className="p-xxl-r">{t("COMMON.SCHEME_TYPE_ID")} : {data.scheme_type_id ? data.scheme_type_id : ""}</span>
                                  <span>{t("IF.MONTH")} : {data.scheme_expiry_date ? expiryMonth(data): 'NA'}</span>
                                  
                                   
                                </h2>
                                <ul
                                  className="right-action-item"
                                  onClick={handleNestedClicks}
                                > 
  
                                  {/* {
                                    !data.is_expired && data.id &&
                                    <li>
                                      <i className="ic-content_copy"
                                        onClick={() => {handleCopyIncentive(element, data);}}></i>
                                    </li>
                                   
                                  } */}
  
                                  <div className="on-off-switch">
                                    {
                                      (!data.is_expired)?
                                      <ToggleSwitch
                                      status={(data.status===2)?0:1}
                                      is_expired={data.is_expired}
                                      onChange={() => {showModalpopup("ModifyStatusPopup",data);}}
                                      />:
                                      <ToggleSwitch
                                        status={(data.status===2)?0:1}
                                        is_expired={data.is_expired}
                                        onChange={''}
                                      />
                                    }
                                   
                                  </div> 
   
                                
                                  { 
                                    (!data.is_expired)?
                                    userInfo.config && userInfo.config.INCENTIVE_PAGE.hasOwnProperty('DELETE') &&
                                    <li
                                      onClick={() => {showModalpopup("DeleteIncentivepopup",data); }}
                                    >
                                      <i className="ic-delete-icn"></i>
                                    </li>
                                    :
                                    userInfo.config && userInfo.config.INCENTIVE_PAGE.hasOwnProperty('DELETE') &&
                                    <li>
                                      <i className="ic-delete-icn"></i>
                                    </li>
                                  }
  
                                </ul>
                              </AccordionItemButton>
                            </AccordionItemHeading>
  
                            <IfAccordionItemPanel
                              tempErrors={
                                tempErrors && tempErrors[index]
                                  ? tempErrors[index]
                                  : {}
                              }
                              incentive_config={incentive_config}
                              element={data}
                              element_index={index}
                              minMaxDate={props.minMaxDate}
                              handleChange={(e, i, q, u) => {
                                props.handleChange(e, i, q, u);
                              }}
                              handleDeleteImage={(e, i, q, u) => {
                                props.handleDeleteImage(e, i, q, u);
                              }}
                              
                              handleUpdate={(e) => {
                                props.handleUpdate(e,"",index + "__" + data.id,index);
                              }}
                              handleCancel={(e) => {props.handleCancel(e);}} 
                              
                              indexKey={index}
                              record={element}
                              incentive_list={incentive_list}
                              loading={loading}
                              addMoreIncentive = {addMoreIncentive}
                              removeMoreIncentive = {removeMoreIncentive}
                            />
                          </AccordionItem>
                        ))}
                    </Accordion>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
  
            {/* </div> */}
          </div>
  
            {deleteIncentivepopup ? (
                <ConfirmationModal
                confirmationData={{
                    show: deleteIncentivepopup,
                    confirmationText:t("MESSAGE.DELETE_INCENTIVE"),
                    type: elementSelected,
                }}
                confirmationModalResponse={(data) =>confirmationModalResponse(data)}
                />
            ) : null}
  
            {modifyStatusPopup ? (
                <ConfirmationModal
                confirmationData={{
                    show: modifyStatusPopup,
                    confirmationText: (elementSelected.status===2)
                    ? t("MESSAGE.ACTIVE_INCENTIVE")
                    : t("MESSAGE.INACTIVE_INCENTIVE"),
                    type: elementSelected,
                }}
                confirmationModalResponse={(data) =>activeModalResponse(data, elementSelected)}
                />
            ) : null}
        </React.Fragment>
    );


}


export default MasterIfIncentive;