import React, { useState, useEffect } from 'react';

import { Tab, Nav } from "react-bootstrap";
import FullPageLoader from '../../common/FullPageLoader';
import MasterConfig from './MasterConfig';
import { withTranslation } from 'react-i18next';
import incentive_img from "../../../webroot/images/incentive_scheme_img.svg";
import { IncentiveService,PointService } from "../../../services";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CommonActions } from "../../../store/action";
import { NULL } from 'sass';



const AgentConfigPanel = (props) => {

    const [configList, setConfigList] = useState([]);
    const [activeTab, setState] = useState(1);
    const [preExpandedTabs, setExpandedTab] = useState(-1);
    const [subExpandedTabs, setSubExpandedTabs] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [configData, setConfigData]=useState({});
    const [expiredConfigList, setExpiredConfigList] = useState([]);
    const [formErrors, setformErrors] = useState({});
    const [financierList, setFinancierList] = useState({});
    const dispatch = useDispatch(); 
   
    useEffect(() => {
        getIncentiveConfig();
        getPointList();
    }, []);

    useEffect(() => {
        getFinancierList();
        // eslint-disable-next-line
    },[])

    const getFinancierList=()=>{
        setLoading(true)
        dispatch(CommonActions.financierList()).then((res) => {
            setLoading(false);
            if (res && res.status === 200) {
                setFinancierList(res.data);
            }
        });
    }

    
    const getIncentiveConfig = () => {
        setLoading(true);
        IncentiveService.getIncentiveConfig().then(function (response){
            setLoading(false);
            if (response.data.status === 200) {
                if (Object.keys(response.data.data).length > 0) {
                    setConfigData(response.data.data)
                }
            } else {
                toast.error(response.data.message);
            }
        }).catch(function (response) {setLoading(false)});
    };

    const getPointList=()=>{
        setLoading(true);
        PointService.getAllAgentPoints().then(function (response){
            if (response.data.status === 200) {
                if (Object.keys(response.data.data).length > 0){
                    let activeResponse = response.data.data.active_data;
                    let expiredResponse = response.data.data.expired_data;
                    setConfigList(activeResponse);
                    localStorage.setItem(
                        "main_config_list",
                        JSON.stringify(response.data.data.active_data)
                    );
                    setExpiredConfigList(expiredResponse);
                    setLoading(false);
                }
            } else {
                setLoading(false);
                toast.error(response.data.message);
            }
        }).catch(function (response) {setLoading(false) });
    }
    

    const onChange = (event) => {
        let { name, value, id } = event.target;
        const updatedArray = configList.map((item, idx) => {
            if (idx === Number(id)) {
                item[name] = value
                return { ...item};
            }
            // Leave the other items unchanged
            return item;
          });
          setConfigList(updatedArray);
    };

    const confirmationModalResponse = (response) => {
        document.body.classList.remove("overflow-hidden");
        if(response.response){
            let newConfig = configList.filter(ob => (ob.id !== response.type.id || ob.temp_id !== response.type.temp_id));
            setConfigList(newConfig);
        }
    }

    const handleChange = (name, value, type,index) => { 
        const updatedArray = configList.map((item, idx) => {
            if (idx === index) {
                if (name === "status") {
                    item[name] = (value)?1:0;
                    setformErrors({[index] : {}});
                } else {
                    item[name] = value;
                    setformErrors({[index] : {}});
                }
   
                return {...item};
            }
            // Leave the other items unchanged
            return item;
          });
          // Update the state with the modified array
          setConfigList(updatedArray);
    };

    const showExpandedTabUuidTabs = (uuid) => {
        setExpandedTab(uuid);
    }
    const showSubExpandedTabUuid = (uuid) => {
        setSubExpandedTabs(uuid);
    }

    const handleAddPointConfig = () => { 
        let tempConfig = {
        point_config: [
            {
                finanicer_ids: [],
                start_date : null,
                end_date : null,
                multiplier: null,
                status: 1,
                financier_flag:0, 
            }
        ],
        tempErrors: {},
        product_type : 'UCRF',
        application_type : 'UCRF',
        user_role: 53,
        reset_month : 3,
        year : new Date().getFullYear(),
        status:1,
        loading: false,
        activeItem: 1,
        description: "",
        temp_id:(configList && configList.length)? configList.length+1:0
        };
         
        
        const point_config = [];
        point_config.push(tempConfig);
        setformErrors({[configList.length] : {}});
        setConfigList([...configList, ...point_config]);
        setExpandedTab(0);
       
    };

    


    const validUpdateForm = (data, otherIncentiveList) => {   
        let formIsValid = true;
        let tempErrors = {};

        if(!data.user_role){
            formIsValid = false;
            tempErrors["user_role"] = "User Role is Required"
        }

        if(!data.year){
            formIsValid = false;
            tempErrors["year"] = "Year is Required";
        }

        if(!data.product_type){
            formIsValid = false;
            tempErrors["product_type"] = "Sales Team is Required";
        }

        if(data["user_role"] && data["year"] && data["product_type"] && otherIncentiveList && otherIncentiveList.length > 0){
            let duplicatePointConfig = otherIncentiveList.some((list) => {
                return ((list.user_role === data["user_role"]) && (list.year === data["year"]) && (list.product_type === data["product_type"]));
            });
            if(duplicatePointConfig){
                formIsValid = false;
                tempErrors["user_role"] = props.t('SCORE_CONFIG_DETAIL.MESSAGE.ROLE_ALREADY_EXIST');
                tempErrors["year"] = props.t('AGENT_POINT_CONFIG.YEAR_ALREADY_EXIST');
                tempErrors["product_type"] = props.t('SCORE_CONFIG_DETAIL.MESSAGE.SALES_ALREADY_EXIST');
            }
        }

        if(!data.point_config || data.point_config.length === 0){
            formIsValid = false;
            tempErrors["point_config"] = props.t('SCORE_CONFIG_DETAIL.REQUIRED_MSG.SCORE_DETAIL');;
        }
        if (!data["application_type"]) {
            formIsValid = false;
            tempErrors["application_type"] = props.t('VALIDATION.APPLICATION_TYPE');
        } 
        
        if (!data["reset_month"]) {
            formIsValid = false;
            tempErrors["reset_month"] = props.t('VALIDATION.RESET_FREQUENCY');
        }
        
        if(data.point_config && data.point_config.length > 0){
            let getAllFinanciers = {};
            let financierDateMap = {};
            data.point_config.forEach((element, index) => { 
                if(element.status !== 0 || element.status ===1){

                    getAllFinanciers[element.application_type] = !getAllFinanciers[element.application_type] ? element.finanicer_ids.length : getAllFinanciers[element.application_type] + element.finanicer_ids.length
    
                    if (!element.finanicer_ids.length) {
                        formIsValid = false;
                        tempErrors["finanicer_ids_" + index] = props.t('VALIDATION.FINANCERS_REQUIRED');
                    }
                    if (element.multiplier=== "" || !element.multiplier ) {
                        formIsValid = false;
                        tempErrors["multiplier_" + index] = props.t('AGENT_POINT_CONFIG.MULTIPLIER_REQUIRED');
                    }else{
                        if(parseFloat(element.multiplier) < 1 || parseFloat(element.multiplier) > 10){
                            formIsValid = false;
                            tempErrors["multiplier_" + index] = props.t('VALIDATION.MULTIPLIER_RANGE');
                        }
                    }
                    
                    if (!element.start_date) {
                        formIsValid = false;
                        tempErrors["start_date_" + index] = props.t('VALIDATION.START_DATE');
                    }

                    if (!element.end_date) {
                        formIsValid = false;
                        tempErrors["end_date_" + index] = props.t('VALIDATION.END_DATE');
                    }

                    if (element.start_date && element.end_date) {
                        const startDate = new Date(element.start_date);
                        const endDate = new Date(element.end_date);
                     
                        if (startDate > endDate) {
                            formIsValid = false;
                            tempErrors["start_date_" + index] = props.t('VALIDATION.START_DATE_LESS_THAN_END_DATE');
                        } else {

                            // Check for duplicate financier IDs across overlapping date ranges
                            element.finanicer_ids?.forEach(financierId => {
                                if (financierDateMap[financierId]) { 
                                    const existingRanges = financierDateMap[financierId];
                                    existingRanges.forEach(range => {
                                        const existingStartDate = new Date(range.start);
                                        const existingEndDate = new Date(range.end);

                                        if (startDate <= existingEndDate && endDate >= existingStartDate) {
                                            formIsValid = false;
                                            tempErrors["finanicer_ids_" + index] = props.t('VALIDATION.MULTIPLE_FINANCIER_DATE_RANGE');
                                        }
                                    });
 
                                    financierDateMap[financierId].push({ start: element.start_date, end: element.end_date });
                                } else {
                                    
                                    financierDateMap[financierId] = [{ start: element.start_date, end: element.end_date }];
                                }
                            });
                        }
                    }
                    
                }
            })
        }
       
        return {formIsValid, tempErrors};
    };

    const handleCancel = (element) => {

        setSubExpandedTabs("");; //CLOSE CURRENT TAB

        let { id } = element;
        let tempConfigList = configList;
        let main_config_list = JSON.parse(localStorage.getItem("main_config_list")) ? JSON.parse(localStorage.getItem("main_config_list"))  : {};
        let main_index = 0;
        if (id) {
            main_index = main_config_list.findIndex(
                (p) => p.id === id
            );
        } else {
            main_index = main_config_list.length;
        }
        let main_data = main_config_list[main_index];

        let index = 0;
        if (id) {
            index = tempConfigList.findIndex(
                (p) => p.id === id
            );
        } else {
            index = tempConfigList.length - 1;
        }
        if (main_index === main_config_list.length) {
            tempConfigList = tempConfigList.filter((obj, key)=> key !== index)
            setConfigList(tempConfigList)
        } else {
            tempConfigList[index] = main_data;
            setConfigList(tempConfigList)
        }
    };

 
    const handleUpdate = async (element, indexKey,childRefKey='') => {
        if (childRefKey) {
            setSubExpandedTabs(childRefKey);
        }
        let inputField = element;
        
        let otherconfigList = configList.filter((list, index) => index !== indexKey);
        const {formIsValid, tempErrors} = validUpdateForm(inputField, otherconfigList);
        setformErrors({[indexKey] : tempErrors});
        if(formIsValid){
            saveConfigData(inputField);
        }
    };

   
    const saveConfigData=async(inputField)=>{
        setLoading(true);
        var formData = new FormData();
        formData.append("user_role", inputField.user_role);
        formData.append("product_type", inputField.product_type);
        formData.append("application_type", inputField.application_type);
        formData.append("expiry_year", inputField.year); 
        formData.append("status", (inputField.status) ? 1 : 0); 
        formData.append("point_config", JSON.stringify(inputField.point_config)); 
        formData.append("reset_month", inputField.reset_month); 
        if(inputField.id){
            formData.append("id", Number(inputField.id));
        }  
        PointService.saveAgentConfig(formData).then(function (response){
            setLoading(false);
            if(response.data.status === 200){
                toast.success(response.data.message);
                getPointList();
            }else{
                toast.error(response.data.message); 
            }
        }).catch(function (response) {
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <FullPageLoader show={loading} />
            <div className='container-fluid '>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className="tab-line white-bg p-l-r-15 minus-l-r-15 hading-tab-top">
                        <div className="top-heading">
                            <h1>{props.t('COMMON.POINT_CONFIG.HEADING')}</h1>

                            <Nav variant="pills" className="flex-column">
                                <div className="tab-list">
                                    <Nav.Item
                                        onClick={() => {
                                            setState(1);
                                        }}
                                    >
                                        <Nav.Link eventKey="first">{props.t('COMMON.POINT_CONFIG.ACTIVE_CONFIG')}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item
                                        onClick={() => {
                                            setState(2);
                                        }}
                                    >
                                        <Nav.Link eventKey="second">{props.t('COMMON.POINT_CONFIG.EXPIRED_CONFIG')}</Nav.Link>
                                    </Nav.Item>
                                </div>
                            </Nav>

                        </div>

                        {activeTab === 1 && (
                            <button
                                onClick={() => {
                                    handleAddPointConfig();
                                }}
                                className="btn-line btn-add-incentive"
                            >
                                <i className="ic-add"></i>
                                <span>{props.t('COMMON.POINT_CONFIG.ADD_BTN')}</span>
                            </button>
                        )}

                    </div>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <div className="add-incentive-accordion">
                                {(configList && configList.length > 0 && Object.keys(configData).length > 0) ?
                                    (<MasterConfig
                                        activeTab={activeTab}
                                        configList={configList}
                                        configData={configData}
                                        financierList={financierList}
                                        index={0}
                                        handleChange={(e, i, q, u) => {
                                            handleChange(e, i, q, u);
                                        }}
                                        handleCancel={(e) => {
                                            handleCancel(e);
                                        }}
                                        onChange={(e, i, q, u) => onChange(e, i, q, u)}
                                        confirmationModalResponse={confirmationModalResponse}
                                        preExpandedTabs={preExpandedTabs}
                                        showExpandedTabUuid={showExpandedTabUuidTabs}
                                        subExpandedTabs={subExpandedTabs}
                                        getPointList={getPointList}
                                        showSubExpandedTabUuid={showSubExpandedTabUuid}
                                        handleAddPointConfig={handleAddPointConfig}
                                        loading={loading}
                                        handleUpdate={handleUpdate}
                                        tempErrors={formErrors}
                                    />
                                    ) : (
                                        <div className="active-scheme-outer">
                                            <div className="white-bg">
                                                <img src={incentive_img} className="" alt="" />
                                                <span>{props.t('AGENT_POINT_CONFIG.NO_CONFIG_FOUND')}</span>
                                                <button
                                                    className="btn-primary"
                                                    onClick={handleAddPointConfig}
                                                >
                                                    {props.t('COMMON.POINT_CONFIG.ADD_BTN')} </button>
                                            </div>
                                        </div>
                                    )

                                }
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <div className="add-incentive-accordion">
                            {(expiredConfigList && expiredConfigList.length > 0 && Object.keys(configData).length > 0)  ? 
                            (<MasterConfig
                                activeTab={activeTab}
                                configList={expiredConfigList}
                                configData={configData}
                                financierList={financierList}
                                index={0}
                                handleChange={(e, i, q, u) => {
                                    handleChange(e, i, q, u);
                                }}
                               
                                onChange={(e, i, q, u) => onChange(e, i, q, u)}
                                confirmationModalResponse={confirmationModalResponse}
                                preExpandedTabs={preExpandedTabs}
                                showExpandedTabUuid={showExpandedTabUuidTabs}
                                subExpandedTabs={subExpandedTabs}
                                showSubExpandedTabUuid={showSubExpandedTabUuid}
                                handleAddPointConfig={handleAddPointConfig}
                                getPointList={getPointList}
                                loading={loading}
                                handleUpdate={(e, i, q) => {
                                    handleUpdate(e, i, q);
                                }}
                                tempErrors={formErrors}
                            />
                            ) : (
                                <div className="active-scheme-outer">
                                    <div className="white-bg">
                                        <img src={incentive_img} className="" alt="" />
                                        <span>{props.t('AGENT_POINT_CONFIG.NO_EXPIRED_CONFIG_FOUND')}</span>
                                    </div>
                                </div>
                            )}
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>

            </div>

        </React.Fragment>
    );
   
}


export default withTranslation('common')(AgentConfigPanel); 