import { IfConstants } from '../constants/if.constants';
import { IfService } from "../../services";


const ifSchemeList = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IfService.getIfSchemType(params)
                .then((response) => {
                    if (response && response.status === 200 && response.data.status === 200) {
                        let detail = response.data.data.map(ob => {
                            return { ...ob, value: ob.id, label: ob.scheme }
                        });
                        dispatch({ type: IfConstants.IF_SCHEME_LIST_REQUEST, payload: detail })
                        dispatch({ type: IfConstants.IF_GET_ROLE_LIST, payload: { [params.role_id]: detail } })
                        resolve(detail);

                    }
                    else {
                        resolve([]);
                    }
                })
        })
    }
}

const getIfConfig = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IfService.getIfConfig(params)
                .then((response) => {
                    if (response && response.status === 200 && response.data.status === 200) {
                        let detail = response.data.data;
                        dispatch({ type: IfConstants.IF_CONFIG_FILTERS, payload: detail })
                        resolve(response.data)
                    } else {
                        resolve(response && response.data)
                    }
                }).catch(err => resolve(err))
        });
    }
}


export const IfActions = {
    ifSchemeList,
    getIfConfig
};