
const validateDataForZero =(data) => {
    let zeroNotExist = false;

    if((data.form_config.FRESH_DISBURSAL_CARD || data.form_config.DEALER_ACTIVATED_CARD || data.form_config.RENEWAL_REPAYMENT_CARD) && data[data.form_config.KEY]) {
        data[data.form_config.KEY].forEach((element, index) => {
            if (Number(element.min_disb_per) === 0 || Number(element.max_disb_per) ===0 || Number(element.incentive_amount) === 0) {
                zeroNotExist = true;
            }
        });
    }
    if(data.form_config.ADDITION_INC_CARD  && data[data.form_config.KEY]) {
        data[data.form_config.KEY].forEach((element, index) => {
            if (Number(element.target_amount) === 0 || Number(element.incentive_amount) === 0) {
                zeroNotExist = true;
            }
        });
    }
    if(data.form_config.ACTIVATED_ONBOARD_CARD  && data[data.form_config.KEY]) {
        data[data.form_config.KEY].forEach((element, index) => {
            if (Number(element.onboard_limit) === 0 || Number(element.incentive_amount) === 0) {
                zeroNotExist = true;
            }
        });
    }
    
    return zeroNotExist;
};

export default validateDataForZero;