import { CommonConstants } from '../constants/common.constants'; 

 
export default function CommonReducer(state = {}, action) { 
  switch (action.type) { 
    case CommonConstants.STATE_LIST_REQUEST:
      return {
        ...state,
        stateList: action.payload,
      };  
      
    case CommonConstants.USER_LIST_REQUEST:
      return {
        ...state,
        userList: action.payload,
      };  

    case CommonConstants.SCHEME_LIST_REQUEST: 
      return {
        ...state,
        schemeList: action.payload,
      }; 
    case CommonConstants.GET_ROLE_LIST: 
      return {
        ...state,
        getRoleList: action.payload,
      };
    case CommonConstants.FINANCIER_LIST_REQUEST: 
      return {
        ...state,
        financierList: action.payload,
      };  
      
    case CommonConstants.ROLEWISE_SCHEME_LIST: 
      return {
        ...state,
        roleSchemeList: (state && state.roleSchemeList) ? {...state.roleSchemeList, ...action.payload} : action.payload
      };

    case CommonConstants.BM_USER_LIST: 
      return {
        ...state,
        bmUserList: action.payload,
      }; 
    
    case CommonConstants.ROLE_PRODUCTS_LIST: 
      return {
        ...state,
        roleProductList: action.payload,
      }; 
    
    case CommonConstants.APPROVE_BUTTON:
      return {
        ...state,
        approveButton: action.payload
      };
    case CommonConstants.ADMIN_DASHBOARD: 
      return {
        ...state,
        adminDashboard: action.payload,
      }; 
    case CommonConstants.PAYOUT_DETAILS: 
      return {
        ...state,
        payoutDetails: action.payload.detail,
        payoutReqParams:action.payload.params
      };
    case CommonConstants.INCENTIVE_CONFIG_FILTERS: 
      return {
        ...state,
        incentiveConfig: action.payload,
      }; 
    case CommonConstants.CSV_LOG_HISTORY: 
      return {
        ...state,
        getCsvUploadHistory: action.payload,
      };
      case CommonConstants.SAVE_INCENTIVE_USER_DATA: 
      return {
        ...state,
        saveIncentiveUserData: action.payload,
      };
      case CommonConstants.GET_MULTISELECTED_PAYOUTS: 
      return {
        ...state,
        getMultiselectedPayouts: action.payload,
      };
      case CommonConstants.LEAD_COUNT_PAYOUT_DETAILS: 
      return {
        ...state,
        leadCountByRole:(state && state.leadCountByRole) ? {...state.leadCountByRole, ...action.payload} : action.payload
      };
      case CommonConstants.SET_MULTISELECTED_EXCLUDED_PAYOUTS: 
      return {
        ...state,
        getMultiselectedExcludedPayouts: action.payload,
      };
      case CommonConstants.CHECKED_ALL_STATUS:
        return {
          ...state,
          checkedallStatus: action.payload
        };
      case CommonConstants.CHECK_FOR_MAIL_APPROVAL: 
        return {
          ...state,
          pdfUploadDtls: action.payload,
        }; 
      case CommonConstants.DEALER_DETAILS: 
      return {
        ...state,
        dealerDetails: action.payload.detail,
        dealerReqParams:action.payload.params
      }; 
      case CommonConstants.DEALER_CONFIGS: 
      return {
        ...state,
        dealerConfigDetail: action.payload
      }; 
      case CommonConstants.DEALER_CATEGORY_DATA: 
      return {
        ...state,
        dealerCategoryConfig: action.payload
      }; 
      case CommonConstants.FINANCIER_GROUP_CONFIG: 
      return {
        ...state,
        financierGroupConfig: action.payload
      }; 
      case CommonConstants.DEVIATION_LIMIT_CONFIG:
      return {
        ...state,
        deviationLimitConfig: action.payload
      }; 
      case CommonConstants.PAGE_STATUS:
        return {
          ...state,
          page_status: action.payload
        };
      case CommonConstants.IF_PAYOUT_DETAILS: 
        return {
          ...state,
          ifPayoutDetails: action.payload.detail,
          ifPayoutReqParams:action.payload.params
        };
    default:
      return state
  }
}