import { UserReducer,CommonReducer,IfReducer } from './reducer';

import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';


const loggerMiddleware = createLogger();

const appReducer = combineReducers({ auth: UserReducer,common:CommonReducer,if:IfReducer });

//WHEN USER LOGOUT CLEAR ALL REDUX STATE
const rootReducer = (state, action) => {
  if (action.type === 'USERS_LOGOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

let middleware = [];
if (process.env.NODE_ENV === 'dev') { //set development to show errors
  middleware = [...middleware, thunkMiddleware, loggerMiddleware];
} else {
  middleware = [...middleware, thunkMiddleware];
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	rootReducer,
	composeEnhancer(applyMiddleware(...middleware)),
  );

export default store;
