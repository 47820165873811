import { IncentiveConstants } from '../constants/incentive.constants';
import { IncentiveService } from "../../services";

const getUserIncentiveLeadList = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.getUserIncentiveLeadList(params)
                .then((response) => {
                    if (response && response.status === 200 && response.data.status === 200) {

                        let detail = response.data.data;

                        dispatch({ type: IncentiveConstants.USER_INCENTIVE_LEAD_LIST, payload: detail })

                        resolve(detail);

                    }
                    else {
                        resolve([]);
                    }
                })
        })
    }
}

const updateIncentiveAmount = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.updateIncentiveAmount(params)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        resolve(response.data)
                    } else {
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        });
    }
}

const getLoanIdList = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.getLoanIdList(params)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        resolve(response.data)
                    } else {
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        });
    }
}

const updatePpdStatus = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            IncentiveService.updatePpdStatus(params)
                .then((response) => {
                    if (response.status === 200 && response.data.status === 200) {
                        resolve(response.data)
                    } else {
                        resolve(response.data)
                    }
                }).catch(err => resolve(err))
        });
    }
}

function uploadPpdCSV(postData, headers) {
    return new Promise((resolve, reject) => {
        IncentiveService.uploadPpdCSV(postData, headers)
            .then((response) => {
                if (response.status === 200 && response.data.status === 200) {
                    resolve(response.data)
                } else {
                    resolve(response.data)
                }
            }).catch(err => resolve(err)) 
    });
    
}

const updateIfIncentiveAmount = (params) => { 
    return new Promise((resolve, reject) => {
        IncentiveService.updateIfIncentiveAmount(params)
            .then((response) => {
                if (response.status === 200 && response.data.status === 200) {
                    resolve(response.data)
                } else {
                    resolve(response.data)
                }
            }).catch(err => resolve(err))
    }); 
}

export const IncentiveActions = {
    getUserIncentiveLeadList,
    updateIncentiveAmount,
    getLoanIdList,
    updatePpdStatus,
    uploadPpdCSV,
    updateIfIncentiveAmount
};

