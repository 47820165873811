import React, { useEffect, useState } from 'react'
import Modal from '../../common/Modal'; 
import { CommonActions } from "../../../store/action"; 
import DateFormat from 'dateformat';
import { useTranslation } from "react-i18next"; 

function PayoutDetailsHistory(props) { 
    const [historylist, setList] = useState([]); 
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation("common");


    useEffect(() => { 
        getHistoryList();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getHistoryList = () => {
        setLoading(true)
        CommonActions.ifHistoryList({ incentive_user_id: props.id}).then(function (response) {
            setLoading(false)

            if (response && response.status === 200) {
                setList(response.data);
            }
        }).catch(err=> setLoading(false));
    }
  

    return (
        <div>
            <div className="view-timeline-popup">
                <Modal show={props.viewTimeLine} handleClose={() => props.showModalViewTimeLine(false)} >
                    { 
                        <div className="modal-header">
                            <h2>
                                {t('DETAIL.HISTORY')} 
                            </h2>
                        </div> 
                    }

                    <div className="modal-body" >
                        <div className="timeline-history-tabel">
                            <table>
                                <tbody>
                                    {
                                        !loading && historylist.length ? historylist.map((item) => {
                                            return (<tr key={item.id}>
                                                <td>
                                                    <span className="date">{DateFormat(item.created_at, "dd mmm")}</span>
                                                    <span className="time">{DateFormat(item.created_at, "h:MM TT")}</span>
                                                </td>
                                                <td>
                                                    <span className="heading wordWrap">{item.comment || 'NA'}</span>
                                                    {
                                                        item.created_by || item.created_user
                                                        ?
                                                            <span className="Subheading">{'By ' + (item.created_by || item.created_user)}</span>
                                                        :
                                                            ""
                                                    }
                                                </td>

                                            </tr>)
                                        })
                                            : ( props.type === 'LIST' ) ? <tr><td colSpan={2} className="no-data">{loading ? 'loading....' : "No Data Found"}</td></tr> : null
                                    }
                                </tbody>
                            </table>
                        </div> 

                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default PayoutDetailsHistory