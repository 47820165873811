import React, { useEffect } from 'react'
import Modal from '../common/Modal'
import { useSelector, useDispatch } from "react-redux";
import { CommonActions } from "../../store/action";
import { useTranslation } from 'react-i18next';

function RoleList(props) {
   
    const { t } = useTranslation('common');
    const dispatch = useDispatch(); 
    const rolesList = useSelector((state) => { 
        return (state.common && state.common.getRoleList) ? state.common.getRoleList : [] 
        
    })

    useEffect(() => {
        if (!rolesList.length) {
            dispatch(CommonActions.getRoleList());
        }
 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSchemeList = (roleId) => { 
        props.showSchemePopup(roleId)
    }

    return (
        <div className="add-incentive-popup model-popup-outer " 
        style={{ cursor: 'pointer' }}
        >
            <Modal show={props.isOpen} handleClose={props.handleClose} >
                <div className="modal-header">
                
                    <h2>{t('COMMON.ADD_INCENTIVE_SCHEME')}</h2>
                </div>
                <div className="modal-body">
                    <div className='add-incentive-data'>
                        <ul>
                            {
                                rolesList && rolesList.filter(ob=>ob[props.type]===1).map(role =>
                                    <li onClick={() => getSchemeList(role.id)} key={role.id}>{role.name}</li>
                                )
                            }
                        </ul>
                    </div>

                </div>
            </Modal>
        </div>
    )
}
export default RoleList;