import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import AgentPointConfigTable from "./AgentPointConfigTable";
import { CommonActions } from "../../../store/action";
import { useDispatch, } from "react-redux";
import FullPageLoader from "../../common/FullPageLoader"; 
import { useNavigate } from "react-router-dom"; 
import AgentPointExport from "./AgentPointExport";

const AgentPointConfig = (props) => {
    const { t } = useTranslation("common");
    const [loading, setLoading] = useState(false);
    const [categoryDetails, setCategoryDetails] = useState([]); 
    const [searchKey, setSearchKey] = useState(''); 
    const [exportedPopUp, setExportedPopUp] = useState(false);
    const history = useNavigate();
        
    useEffect(() => {
        getDealerConfigDetails(); 
            // eslint-disable-next-line 
    }, []);

    const getDealerConfigDetails = (param = {}) => {  
        let paramOb = param;   
        if(typeof paramOb.page_no == "undefined"){
            paramOb.page = 1;
        } else {
            paramOb.page = paramOb.page_no || 1;
        }
        setLoading(true) 
        CommonActions.getAgentPointDetails(paramOb).then(res => {
            setLoading(false); 
            if (res.status === 200 && res?.data?.length) { 
                setCategoryDetails(res);
            } else {
                setCategoryDetails([]); 
            }
        });

    }; 
    
    const showModalExportedPopUp = () => {
        setExportedPopUp(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideModalExportedPopUp = () => {
        setExportedPopUp(false)
        document.body.classList.remove("overflow-hidden");
    }

    const redirectConfigPanel = () =>{ 
      history('/agent-config-panel');

    } 

    const apiResponse = (val) => {
        if (val.key === 'Enter') {
            getDealerConfigDetails({search_text: searchKey, page_no : 1 })
        }
    }
 
    return (
         <React.Fragment>
                <FullPageLoader show={loading} />
               <div className="dashboard-outer">
                    <div className="container-fluid">
                    <div className="top-heading d-flex">
                        <h1>{t('AGENT_POINT_CONFIG.DASHBOARD_HEADER')}</h1> 
                        <div className="report-heading-right-panel ">
                            <div className='search-bx m-sm-r'>
                                <div className='material'>
                                    <input type="text" placeholder='Search by SFA ID, Name, Phone or Email' className='form-input' maxLength={200} value={null}
                                        onKeyPress={(e) => apiResponse(e)}
                                        onChange={(e) => setSearchKey(e.target.value)}
                                    />
                                    <i className='ic-search'></i>
                                </div>
                            </div>
                        <button className="btn-line m-sm-r" onClick={() => redirectConfigPanel()}>{t("AGENT_POINT_CONFIG.CONFIG_PANEL")}</button>
                        <button className="btn-line btn-export m-sm-l" disabled={categoryDetails?.data?.length > 0 ? false : true} onClick={showModalExportedPopUp} > <i className="ic-export"></i>{t("DEALER.EXPORT")} </ button>
                           
                        </div>
                    </div> 

                    <AgentPointConfigTable categoryDetails={categoryDetails} getDealerConfigDetails={getDealerConfigDetails}/>
                    </div>
                </div>

            {
                exportedPopUp && <AgentPointExport show={exportedPopUp} handleClose={hideModalExportedPopUp} />
            } 

          
        </React.Fragment> 
    );
};

export default AgentPointConfig;
