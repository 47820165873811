import React,{ useEffect, useState } from "react"; 
import MultiSelect from '../common/MultiSelect'; 
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InputField from "../common/InputField";
import { BiPlusCircle, BiMinusCircle }   from "react-icons/bi";
import { PointService } from "../../services"; 
import ConfirmationModal from '../common/ConfirmationModal'; 
import { toast } from "react-toastify";

const ScoreDetails = (props) => {
    const [refresh, setRefresh] = useState(false);
    const [financerOption, setFinancerOption] = useState({});
    const [financierFormListData, setFinancierFormListData] = useState([
        { finanicer_ids: [] },
    ]);
    const commonRedux = useSelector((state) => state.common);
    const [selecteApplicationTypes, setSelecteApplicationTypes] = useState([]);
    const [allSelectedFinanciers, setAllSelectedFinanciers] = useState({}); 
    const [removeDetail, setRemoveDetail] = useState(false);
    const [removeInfo, setRemoveInfo] = useState({});
    
    useEffect(() => {
        if (
            selecteApplicationTypes &&
            selecteApplicationTypes.length &&
            commonRedux &&
            commonRedux.financierList &&
            commonRedux.financierList.length
        ) {
            let allFinanciers = {};
            for (let appType of selecteApplicationTypes) {
                appType = appType.toLowerCase();

                let getFinancierListProductWise = commonRedux.financierList.filter(
                    (elm) => appType === elm.application_type_name.toLowerCase()
                );
                if (getFinancierListProductWise && getFinancierListProductWise.length) {
                    allFinanciers[appType.toLowerCase()] =
                        getFinancierListProductWise[0]["financier"];
                }
            }
            setFinancerOption(allFinanciers);
        }
    }, [commonRedux, selecteApplicationTypes]);

    useEffect(() => {
        if (
            props.element &&
            props.element.application_type &&
            props.element.application_type !== selecteApplicationTypes.join(",")
        ) {
            setSelecteApplicationTypes(props.element.application_type.split(","));
        }

        if (props.element && props.element.score_config) {
            let selectedFinanciers = {};
            for (let financier of props.element.score_config) {
                if (!selectedFinanciers[financier["application_type"]])
                    selectedFinanciers[financier["application_type"]] = [];

                selectedFinanciers[financier["application_type"]] = [
                    ...selectedFinanciers[financier["application_type"]],
                    ...financier["finanicer_ids"],
                ];
            }

            setAllSelectedFinanciers(selectedFinanciers);
        }
    }, [props]);  // eslint-disable-line


    useEffect(() => {
        if (
            props.element.score_config &&
            props.element.score_config.length
        ) {
            setFinancierFormListData(props.element.score_config);
        }

    }, [props]);

    const getDropdownButtonLabel = (event, financierIndex) => {
        let { placeholderButtonLabel, value } = event;

        if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {
            let actualVals = value.filter(val=> (val.value !== "all" && Object.keys(val).length)) 
            return `${actualVals.length} selected`;
        } else return `${placeholderButtonLabel}`;
    };

    /**
     * CHANGE HANDLER FOR SELECTBOX/INPUTBOX
     * @param {*} name
     * @param {*} value
     * @param {*} financierIndex
     */
    const changeHandler = 
        (name,event,value,financierIndex,appType,finacierOptionUpdated) => {

        let OrigName = name.split("_");
        OrigName.pop();
        OrigName = OrigName.join("_");

        if (event.action === "select-option" && event.option.value === "all") {

            financierFormListData[financierIndex][OrigName] = finacierOptionUpdated.map((elm) => "" + elm.value);
            
        }else if(event.action === "deselect-option" && event.option.value === "all"){
                financierFormListData[financierIndex][OrigName] = [];
        }
        else if (event.action === "deselect-option") {
            financierFormListData[financierIndex][OrigName] = (value.filter((o) => o.value !== "all" && o.value != event.option.value )).map(v=> ""+v.value);  // eslint-disable-line
        } 
        else {
            financierFormListData[financierIndex][OrigName] = value.map(
                (elm) => "" + elm.value
            );

            if (!allSelectedFinanciers[appType]) {
                allSelectedFinanciers[appType] = [];
            }
            allSelectedFinanciers[appType] = [
                ...allSelectedFinanciers[appType],
                ...financierFormListData[financierIndex][OrigName],
            ];
            
            value = value.filter(v=> Object.keys(v).length && v.value !== 'all');

            if (value.length === finacierOptionUpdated.length) {
                financierFormListData[financierIndex][OrigName] = [...financierFormListData[financierIndex][OrigName]];
            }
        }
        let selectedFinancier={}
        if(financierFormListData.length){
            let finacierData=financierFormListData.filter(el=>el.application_type===appType).map(el=>el.finanicer_ids);

            const finacierFlat = [...new Set(finacierData.flat())];

            selectedFinancier[appType]=(finacierFlat.length)?finacierFlat:0;
        }
        setAllSelectedFinanciers(selectedFinancier);
        setFinancierFormListData(financierFormListData);
        setRefresh(refresh + 1);
        props.element.score_config=financierFormListData
        
    };

    const inputChangeHandler = (name, value, financierIndex, appType) => {
        let OrigName = name.split("_");
        OrigName.pop();
        OrigName = OrigName.join("_");

        if (!financierFormListData[financierIndex])
                financierFormListData[financierIndex] = {};

        financierFormListData[financierIndex][OrigName] = value.target.value;

        setFinancierFormListData(financierFormListData);
        setRefresh(refresh + 1);
        props.element.score_config=financierFormListData
        
    }

    /**
     * ADD NEW FINANCER
    */
    const addNewFinancierConfig = (appType) => {
        let financierData=(financierFormListData.length)?financierFormListData:[];
     
        financierData.push({
            finanicer_ids: [],
            multiplier: "",
            application_type: appType.toLowerCase(),
            status:1,
            financier_flag:1
        });

        setFinancierFormListData(financierData);
        setRefresh(refresh + 1);       
        props.element.score_config=financierData
    };


    const deleteScoreDetails = (data) =>{
        if(data.response){
            let params = data.type;
            let financierData=(financierFormListData.length)?financierFormListData:[];
            PointService.deleteFinancierDetails({id : params.is_deleted}).then( (response)=>{
                if(response.data.status === 200){
                    toast.success(response.data.message);
                    financierData.splice(params.key, 1);
                    setFinancierFormListData(financierData);
                    setRefresh(refresh + 1);
                    props.element.score_config = financierData;
                }else{
                    toast.error(response.data.message); 
                }
            }) 
        }  
        setRemoveDetail(false);
        setRemoveInfo({});

    }


    const removeMore = (e,index,data)=>{  
        e.stopPropagation();
        let financierData=(financierFormListData.length)?financierFormListData:[]; 

        if(data.id){
            setRemoveDetail(true);
            setRemoveInfo({
                key: index,
                index : props.index,
                is_deleted : (data.id)? data.id : false
            });
        } else {
            financierData.splice(index, 1);
            setFinancierFormListData(financierData);
            setRefresh(refresh + 1);       
            props.element.score_config=financierData;
        }


        if(Object.keys(props.tempErrors).length){
            for (let key in props.tempErrors ) {
                if (props.tempErrors.hasOwnProperty(key)) {
                    props.tempErrors[key] = ""
                }
            }
        }

    }
    
    const getInputValues = (name, key) => {
        return financierFormListData[key] && financierFormListData[key][name];
    };
  
    const { t } = useTranslation("common");
    let { tempErrors, element } = props;
    let allSelectedValues = {}

    return (
        <React.Fragment>
            {element.application_type && (
                <span className="config-heading">
                    {t('SCORE_CONFIG_DETAIL.FIELDS.SD')}
                </span>
            )}
            {
                element.application_type &&
                selecteApplicationTypes &&
                selecteApplicationTypes.map((appType, index) =>{
                    appType = appType.toLowerCase();
                    if(financierFormListData.length){
                        let finacierData=financierFormListData.filter(el=>el.application_type===appType).map(el=>el.finanicer_ids);

                        const finacierFlat = [...new Set(finacierData.flat())];

                        allSelectedValues[appType]=(finacierFlat.length)?finacierFlat.length:0;
                    }

                return(<div className="configuration-filed-outer" key={index}>
                    <table>
                        <thead>
                            <tr key={index}>
                                <th>
                                    {appType.toUpperCase()} {t('SCORE_CONFIG_DETAIL.FIELDS.FS')}
                                </th>
                                <th>{t('SCORE_CONFIG_DETAIL.FIELDS.MULTIPLIER')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {   
                                financierFormListData.map((elm, key) => {
                                if (
                                    elm.application_type &&
                                    elm.application_type.toLowerCase() !==
                                    appType
                                ){
                                    return <tr key={key}></tr>;
                                }

                                let financierExclude = [];
                                if (allSelectedFinanciers[appType]) {
                                    financierExclude = allSelectedFinanciers[
                                        appType
                                    ].filter((type) => !elm.finanicer_ids.includes(type));
                                }

                                if (financerOption && financerOption[appType] && financerOption[appType][0].value !== 'all') {
                                    financerOption[appType] = financerOption[appType]    // eslint-disable-line
                                }

                                const finacierOptionUpdated = financerOption && financerOption[appType] && financierExclude? 
                                    financerOption[appType].filter((ob) => (ob.value === 'all' || !financierExclude.includes("" + ob.value))
                                    ): 
                                    financerOption &&financerOption[appType]? financerOption[appType]: [];

                                const selectedValues = (financierFormListData[key] && financierFormListData[key]["finanicer_ids"]) && financerOption[appType]? 
                                    (financierFormListData[key]["finanicer_ids"].length === finacierOptionUpdated.length) ? 
                                        [ 
                                            ...financerOption[appType].filter((opt) => financierFormListData[key]["finanicer_ids"].includes("" + opt.value)) 
                                        ] :  
                                                            
                                        financerOption[appType].filter((opt) => financierFormListData[key]["finanicer_ids"].includes("" + opt.value)): []

                                        return (
                                        <tr key={key}>
                                            <td>
                                                <fieldset className="single-select">  
                                                    <div className="material" key={key}>
                                                        <input
                                                            type="hidden"
                                                            value={elm.application_type}
                                                            name="application_type"
                                                        />
                                                            
                                                        <MultiSelect
                                                        isMulti={true}
                                                        options={finacierOptionUpdated}
                                                        placeholder="Financer"
                                                        iconAfter="false"
                                                        getDropdownButtonLabel={(value) =>
                                                        getDropdownButtonLabel(value, key)}
                                                        value={selectedValues}
                                                        isDisabled={element.is_expired || false}
                                                        onChange={(value, event) =>
                                                        changeHandler("finanicer_ids_" + key,event,value,key,appType,finacierOptionUpdated)}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        />
                                                    </div>

                                                    {tempErrors && tempErrors["finanicer_ids_" + key] ? 
                                                    (
                                                        <span className="error show">
                                                            {tempErrors["finanicer_ids_" + key]}
                                                        </span>
                                                    ) : ("")}
                                                </fieldset>
                                            </td> 
                                            <td>
                                                <fieldset>
                                                <div className="material">
                                                    <InputField
                                                        inputProps={{
                                                        id: "multiplier",
                                                        type: "text",
                                                        name: "multiplier",
                                                        placeholder: " ",
                                                        value:getInputValues("multiplier", key),
                                                        className: "form-input",
                                                        disabled: element.is_expired || false,
                                                        }}
                                                        onChange={(e, i, q, u) => inputChangeHandler("multiplier_" + key, e, key)}
                                                    />
                                                </div>
                                                {
                                                    tempErrors && tempErrors["multiplier_" + key] ? 
                                                    (
                                                        <span className="error show">
                                                            {tempErrors["multiplier_" + key]}
                                                        </span>
                                                    ) : ("")
                                                }
                                                </fieldset>
                                            </td> 
                                            {
                                               (!elm.financier_flag)? 
                                               <td>
                                                {
                                                (element.is_expired)?<span className="add-minus"><BiPlusCircle size="1.4em"/></span>:((financerOption[appType] && allSelectedValues[appType] && financerOption[appType].length !== allSelectedValues[appType]) || !allSelectedValues[appType])?<span className="add-minus" onClick={(e) => {
                                                    addNewFinancierConfig(appType);
                                                }}><BiPlusCircle size="1.4em"/></span>:''  
                                                }
                                               </td>:
                                               <td>
                                                {
                                                    (element.is_expired)?<span className="add-minus"> <BiMinusCircle size="1.4em"/></span>:<span className="add-minus" onClick={(e)=> removeMore(e,key,elm)}> <BiMinusCircle size="1.4em"/></span>
                                                }
                                                </td>
                                            }
                                            <td></td>       
                                        </tr>       
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    {
                        tempErrors &&
                        tempErrors["selectAllFinanciers" + appType] ? 
                        (
                            <span className="error show">
                                {tempErrors["selectAllFinanciers" + appType]}
                            </span>
                        ) : ("")
                    } 

                    {
                        removeDetail ?
                            <ConfirmationModal confirmationData={{ show: removeDetail, confirmationText: t('MESSAGE.DELETE_RECORD'), type: removeInfo }} confirmationModalResponse={(data) => deleteScoreDetails(data)} />
                            : null
                    }
                </div>)
               })
            }
        </React.Fragment>  
    );    
}


export default ScoreDetails;
