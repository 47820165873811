import React, { useState, useEffect } from "react";
import IfLeadListFilter from "./common/IfLeadListFilter";
import { useTranslation } from "react-i18next";
import IfPageHeader from "./common/IfPageHeader";
import { Tab, Nav } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { CommonActions } from "../../store/action";
import IfDashboardListTable from "./common/IfDashboardListTable";
import FullPageLoader from '../common/FullPageLoader';
import * as HELPER from './../../config/helper'; 
import IfPdfUploader from './common/IfPdfUploader';

const IfDashboard = (props) => {
    const { t } = useTranslation("common");
    const [loading, setLoading] = useState(false);

    const [searchFilters, setSearchFilters] = useState({}); 
    const [apiResponse, setApiResponse] = useState({});
    const [searchKey, setSearchKey] = useState();
    const [pdfUploadPopUp, setPdfUploadPopUp] = useState(false);
 

    const dispatch = useDispatch();
    const commonRedux = useSelector((state) => state.common);

    useEffect(() => { 
        let makeDate = new Date(); 
        getDashboardList({incentive_month :makeDate, status : 1}) 
    }, []);
 
    let checkForPdfUpload = commonRedux && commonRedux.pdfUploadDtls && commonRedux.pdfUploadDtls.data ? true : false;

    const getDashboardList = (param) => { 
  
        let paramOb = param;  

        if (typeof paramOb["role_id"] !== "undefined" && +paramOb["role_id"] === 0) {
            delete paramOb["role_id"]
        }

        if(paramOb["role_id"] && !paramOb["role_type"]){
            paramOb["role_type"] = "bm"
        }

        if(!paramOb["status"]){
            paramOb["status"] = 1
        }
 
        //DEL REF FOR RESET FILTER
        if (paramOb.reset) {
            delete paramOb.reset
        }

        if(typeof paramOb.page_no == "undefined"){
            paramOb.page_no = 1;
        } 

        setSearchFilters(paramOb);
        setLoading(true) 
        dispatch(CommonActions.getPdfUploadDtls({month : paramOb?.incentive_month, status : paramOb?.status,  type : 2}));
  
        dispatch(CommonActions.ifPayoutListing(paramOb)).then(res => {
            setLoading(false) 
            if (res.status === 200 && res.data && Object.keys(res.data).length) {
                setApiResponse(res.data); 
            } else {
                setApiResponse(res.data);

            }
        });
    };
 

    /**
     * RESET FILTER
     */
    const resetFilters = (params={}) => { 
        setSearchFilters(params) 
        
        getDashboardList(params, {})
        setSearchKey('');
    }

 
    const setFilter = (params) => {
        setSearchKey('');
        if (params.search_key && params.search_key.trim()) {
            setSearchKey(params.search_key);
        }

    }

    const showModalPdfUploadPopUp = () => {
        setPdfUploadPopUp(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideModalPdfUploadPopUp = () => {
        setPdfUploadPopUp(false)
        document.body.classList.remove("overflow-hidden");
    }

    
    let title= `IF Payout Details (${apiResponse?.data?.data?.length || 0})`; 
 

    return (
        <React.Fragment>
          <FullPageLoader show={loading} />

            <div className="dashboard-outer">
                <div className="container-fluid">
                    <div className="top-heading if-upload-pdf">
                        <h1>{t("COMMON.INCENTIVE_DASHBOARD")}</h1>  
                        <button className="btn-line btn-export" onClick={showModalPdfUploadPopUp} >
                           <i className="ic-upload"></i>
                            {(checkForPdfUpload) ? "Uploaded Incentive T&C" : "Upload Incentive T&C"}
                        </button>
                       
                    </div>
                    <div className="loan-filter-bg dashboard-filter-top">
                        {/* FILTER RESET - SEARCH BUTTON */}
                        <IfLeadListFilter
                            action="if-dashboard"
                            resetFilters={resetFilters}
                            searchFilters={searchFilters}
                            setSearchFilters={setSearchFilters}
                            getDashboardList={getDashboardList}
                            setLoading={setLoading}
                        />
                    </div>


                    <IfPageHeader  
                    action="if-dashboard" 
                    title={title}  
                    pendingRecords={HELPER.numberFormat((apiResponse.paid && apiResponse.paid.pending) || 0)} 
                    checkForPdfUpload = {checkForPdfUpload} 
                    searchFilters={searchFilters}  
                    setFilter={setFilter}
                    getDashboardList={getDashboardList}
                    setLoading={setLoading}/>

                    <div className="payout-summary-tabs">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="All">
                            {/* MAIN HEADING */}
                            <Nav variant="pills" className="flex-column tab-line"> 
                                {apiResponse?.data?.total_incentive  &&  (
                                    <div className="if-payout-total">
                                         <label>{t("COMMON.TOTAL_PAYOUT")} : </label>
                                        <span className="total-payout-txt"> {HELPER.addCurrencyFormatting(apiResponse?.data?.total_incentive)}</span>

                                    </div>
                                )}
                            </Nav>
                            { !loading && Object.keys(apiResponse).length > 0 ?
                                  <IfDashboardListTable apiResponse={apiResponse}  getPayoutDetailsList={getDashboardList} loading={loading} searchFilters={searchFilters} searchKey = {searchKey}/>
                                : (loading)? null: <div className="no-data">{t("COMMON.NO_DATA")}</div>
                            }
                            
                        </Tab.Container>
                    </div>
                </div>

                {
                    pdfUploadPopUp && <IfPdfUploader pdfUploadPopUp={pdfUploadPopUp} hideModalPdfUploadPopUp={hideModalPdfUploadPopUp} month={searchFilters ? searchFilters.incentive_month : new Date()} />
                }
            </div>
        </React.Fragment>
    );
};

export default IfDashboard;
