import {IF_CARD} from "../../../config/constant"
const handleIfAddIncentive = (value, incentiveName,schemeList,incentive_list,incentive_scheme_selected) => { 
    let parent_name = incentiveName;    
    if (incentive_list[value]) {
        incentive_list[value].forEach((el) => {
            if (el.parent_name) {
                parent_name = el.parent_name;
            }
        })
    }
    

    let switch_value = value ? value : incentive_scheme_selected;
    let currentIncentiveCount = (incentive_list[switch_value]) ? incentive_list[switch_value].length : 0;
    let formConfig = (schemeList) ? schemeList.filter(ob => Number(ob.id) === Number(switch_value)) : [];
    
    let incentive_data = {
        product_type: "",
        scheme_duration: "",
        applicable_to: "",
        description: "",
        is_deleted: 0,
        is_expired: 0,
        scheme_type_id: switch_value,
        status: 1,
        parent_name: parent_name,
        temp_id: switch_value + '_' + currentIncentiveCount,
        form_config: (formConfig.length) ? formConfig[0].form_config : {}
    };
    switch_value = switch_value.toString();

   
    switch (true) {
        case (incentive_data.form_config.hasOwnProperty('FRESH_DISBURSAL_CARD')):
            incentive_data[incentive_data.form_config.KEY] = [IF_CARD[incentive_data.form_config.KEY]];
            break; 
        case (incentive_data.form_config.hasOwnProperty('ACTIVATED_ONBOARD_CARD')):
            incentive_data[incentive_data.form_config.KEY] = [IF_CARD[incentive_data.form_config.KEY]];
            break;   
        case (incentive_data.form_config.hasOwnProperty('DEALER_ACTIVATED_CARD')):
            incentive_data[incentive_data.form_config.KEY] = [IF_CARD[incentive_data.form_config.KEY]];
            break; 
        case (incentive_data.form_config.hasOwnProperty('RENEWAL_REPAYMENT_CARD')):
            incentive_data[incentive_data.form_config.KEY] = [IF_CARD[incentive_data.form_config.KEY]];
            break;   
        case (incentive_data.form_config.hasOwnProperty('ADDITION_INC_CARD')):
            incentive_data[incentive_data.form_config.KEY] = [IF_CARD[incentive_data.form_config.KEY]];
            break;                  
       
    }
    if (incentive_list[parseInt(switch_value)]) {
        incentive_list[parseInt(switch_value)].push(incentive_data);
    } else {
        incentive_list[parseInt(switch_value)] = [];
        incentive_list[parseInt(switch_value)].push(incentive_data);
    }
    return {incentive_list, incentive_scheme_selected};

};
export default handleIfAddIncentive;