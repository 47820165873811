import Modal from "../../common/Modal";
import { useTranslation } from 'react-i18next';
import FileUpload from "../../dashboard/common/FileUpload";
import { useEffect, useState } from "react";
import BulkHistoryTable from "../../dashboard/common/BulkHistoryTable"; 


function IfBulkUploader(props) {
    const { t } = useTranslation('common');

    const [fileUplodValue, setBulkUpload] = useState({
        bulkUpload: false,
        bulkUploadStatus: false,
        bulkUploadMsg: false
    })
    const [bulkUploadPopUp, setBulkUploadPopUp] = useState(false);
    const fileUploadType = 5;

    useEffect(()=>{
        //SHOW POPUP IF OPENED
        if(props.handleBulkUploader && props.handleBulkUploader.bulkUpload){
            setBulkUpload({
                ...fileUplodValue, bulkUpload: true,type:1
            })
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleBulkUploader])

 
    const uploadStatusHandler = (params) => {
        setBulkUpload({
            ...fileUplodValue, ...params
        })
    }

    const showModalBulkUploadPopUp = () => {
        setBulkUploadPopUp(true) 
        document.body.classList.add("overflow-hidden");
    }

    const hideModalBulkUploadPopUp = () => {
        setBulkUploadPopUp(false)
        document.body.classList.remove("overflow-hidden");
        props.getDashboardList(props.searchFilters);
    }

    return (

        <>
            <button className="btn-line btn-export m-md-l" onClick={showModalBulkUploadPopUp} > <i className="ic-upload"></i> {t('COMMON.BULK_UPLOAD_HISTORY')}</button>
            <div className={"model-popup-outer bulk-upload-history-popup"}>
                <Modal show={bulkUploadPopUp}  handleClose={()=>hideModalBulkUploadPopUp()}>
                
                    <div className="modal-body">
                    <div className='bulk-upload-history-content'>
                                <div className='bulk-upload-sec'>
                                    <h3>{t('BULK_UPLOAD_DETAIL.IF_SCHEME_HEADER')}</h3>
                                    <FileUpload uploadStatusHandler={uploadStatusHandler} fileUplodValue={fileUplodValue} fileUploadType={fileUploadType} />

                                </div>
                                <BulkHistoryTable  fileUploadType={fileUploadType} />
                            </div> 
                    </div>
                    <div className="modal-footer">
                        <div className="download-txt">
                            Download input format < a href={props.BulkuploadUrl}>
                                Click Here
                            </a>
                        </div>
                    </div>
                </Modal>
            </div> 
        </>
        
  );
}

export default IfBulkUploader;