import React from 'react';
import Select from 'react-select';
import CustomValueContainer from '../../common/CustomValueContainer' ;
import { withTranslation } from 'react-i18next'; 
import MultiSelect  from '../../common/MultiSelect'; 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
 


const BasicDetails = (props) => {
    let  {element,configData,tempErrors}  = props;

    const sales_team = configData.product_type.filter(ob=>ob.incentive_type===1 && ob.value === 'UCRF');
    const product_type_app = configData.product_type.filter(ob=> ob.application_type===1 && ob.value === 'UCRF');
    const role_list = configData.user_point_role.filter(ob=> +ob.value===53);
    
    const handleChangeRole = (RoleTypeOption) => {
        props.onChange("user_role", RoleTypeOption.value, 'role', props.index)
    };

    const handleChangeSalesTeam = (SalesTeamOption) => {
        props.onChange("product_type", SalesTeamOption.value, 'sales', props.index)
    };

    const handleChangeMonths = (MonthOption) => {
        props.onChange("year", MonthOption.value, 'year', props.index)
    }; 

    const handleChangeAppType = (ApplicationTypeOption) => {   
        props.onChange("application_type", ApplicationTypeOption?.value, 'application_type', props.index)
    };

    const handleChangeResetFrequency = (FrequencyOption) => {
        props.onChange("reset_month", FrequencyOption.value, 'reset_month', props.index)
    };

    

        return (
            <React.Fragment>
                <div className='basic-filter-outer'>

                    <div className="basic-detail-filter"> 

                        <fieldset className="single-select">
                            <div className="material">
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                    value={element && element.user_role ? role_list.filter((el) =>(el.value === element.user_role)) : null}
                                    onChange={handleChangeRole}
                                    options={role_list}
                                    placeholder= {props.t('FORM.USER_ROLE')+'*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    isDisabled={element.is_expired}
                                />
                            </div>
                            {
                                (tempErrors && tempErrors.user_role) ? <span className="error show">{tempErrors.user_role}</span> : <span>&nbsp;</span>
                            }
                        </fieldset>

                        <fieldset className="single-select">
                            <div className="material">
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                    value={element && element.product_type ? sales_team.filter(({ value }) => value === element.product_type) : null}
                                    options={sales_team}
                                    onChange={handleChangeSalesTeam}
                                    placeholder= {props.t('FORM.SALE_TEAM') + '*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    isDisabled={element.is_expired}
                                />
                            </div>
                            {
                                (tempErrors && tempErrors.product_type) ? <span className="error show">{tempErrors.product_type}</span> : <span>&nbsp;</span>
                            }
                        </fieldset>

                        <fieldset className="single-select">
                            <div className="material">
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                    value={[{value: '', label: element.year}]}
                                    options={props.yearlist}
                                    onChange={handleChangeMonths}
                                    placeholder={props.t('FORM.YEAR') + '*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    isDisabled={element.is_expired}
                                />
                            </div>
                            {
                                (tempErrors && tempErrors.year) ? <span className="error show">{tempErrors.year}</span> : <span>&nbsp;</span>
                            }
                        </fieldset>


                     
                        <fieldset className="single-select">  
                                <div className="material"> 
                                     <Select components={{ ValueContainer: CustomValueContainer }}
                                        value={[{value: '', label: element.application_type}]}
                                        options={product_type_app}
                                        onChange={handleChangeAppType}
                                        placeholder={props.t('FORM.APPLICATION_TYPE') + '*'}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        isSearchable={false}
                                        isDisabled={element.is_expired}
                                    /> 
                                </div>
                                
                                <label data-label={"Application Type"} className="form-label"></label>

                                {
                                    (tempErrors && tempErrors.application_type) ? <span className="error show">{tempErrors.application_type}</span> : <span>&nbsp;</span>
                                }
                        </fieldset>


                        <fieldset className="single-select">
                            <div className="material">
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                    value={element && element.reset_month ? props.frequencylist.filter(({ value }) => value === element.reset_month) : null}
                                    options={props.frequencylist}
                                    onChange={handleChangeResetFrequency}
                                    placeholder= {props.t('FORM.RESET_FREQUENCY') + '*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                    isDisabled={element.is_expired}
                                />
                            </div>
                            {
                                (tempErrors && tempErrors.reset_month) ? <span className="error show">{tempErrors.reset_month}</span> : <span>&nbsp;</span>
                            }
                        </fieldset>

 
                    </div>
                </div>
            </React.Fragment>
        )
   
}  
 
export default withTranslation('common')(BasicDetails); 
