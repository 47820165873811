 
const HandleIfChange = (name, value, is_confirm, element,incentive_list) => {
    let incentiveList=JSON.parse(JSON.stringify(incentive_list));
    element.scheme_type_id = '' + element.scheme_type_id || 1
    let index = incentiveList[parseInt(element.scheme_type_id)].findIndex(
        (p) => p.id === element.id
    );
    if (index === -1) return false;
    let splitted_name = name.split("_");
    
   
    let inputFieldName = JSON.parse(JSON.stringify(splitted_name));
    inputFieldName.pop();
    let front_name = inputFieldName.join('_');
     
    if (name === "description" ) {
        incentiveList[parseInt(element.scheme_type_id)][index][name] = value;
    }else if (name === "status") {
        if (value === false && !is_confirm) {
        } else {
            incentiveList[parseInt(element.scheme_type_id)][index][name] = value;
        }
    }else if ((element.form_config.FRESH_DISBURSAL_CARD || element.form_config.DEALER_ACTIVATED_CARD || element.form_config.RENEWAL_REPAYMENT_CARD ) && (front_name === "min_disb_per" || front_name === "max_disb_per" || front_name==="incentive_amount")){
        let nameSplit = name.split('_');
        let element_index =  nameSplit[nameSplit.length-1];
        nameSplit.pop();
        let element_name = nameSplit.join('_');
        incentiveList[parseInt(element.scheme_type_id)][index][element.form_config.KEY][element_index][element_name] = value;

    }else if (element.form_config.ACTIVATED_ONBOARD_CARD && (front_name === "operator" || front_name === "onboard_limit" || front_name==="incentive_amount")){
        let nameSplit = name.split('_');
        let element_index =  nameSplit[nameSplit.length-1];
        nameSplit.pop();
        let element_name = nameSplit.join('_');
        incentiveList[parseInt(element.scheme_type_id)][index][element.form_config.KEY][element_index][element_name] = value;

    }else if (element.form_config.ADDITION_INC_CARD && (front_name === "target_amount" || front_name === "if_date" || front_name==="incentive_amount")){
        let nameSplit = name.split('_');
        let element_index =  nameSplit[nameSplit.length-1];
        nameSplit.pop();
        let element_name = nameSplit.join('_');
        incentiveList[parseInt(element.scheme_type_id)][index][element.form_config.KEY][element_index][element_name] = value;

    }else{ 
        let product_type = ''
        if(name === 'product_type' && value.length > 0){
            for (let index = 0; index < value.length; index++) {
                let comma = product_type ? ',':'';
                product_type = product_type + comma + value[index].value;
            }
            element.product_type = product_type
        }
        if(name === 'scheme_duration' && value?.start){
            incentiveList[parseInt(element.scheme_type_id)][index]["scheme_duration_date"] = value.start; 
        } 
        let data = value.value    
        if(value.length > 0){
            data = product_type
        }
        incentiveList[parseInt(element.scheme_type_id)][index][name] = data;
        
        if(name === 'applicable_to' && value){ 
            incentiveList[parseInt(element.scheme_type_id)][index]["applicable_to"] = value;
        } 
    }
    return incentiveList
};


export default HandleIfChange;