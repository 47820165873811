import React, { useState, useEffect } from "react";
import LeadListFilter from "./common/LeadListTopFilter";
import { useTranslation } from "react-i18next";
import PageHeader from "./common/PageHeader";
import { Tab, Nav } from "react-bootstrap";
import { useSelector, useDispatch, } from "react-redux";
import { CommonActions } from "../../store/action";
import PaidAmount from "./PaidAmount";
import PayoutDataTable from './common/PayoutDataTable';
import { useLocation, useNavigate } from 'react-router-dom';
import FullPageLoader from "../common/FullPageLoader";
import { ARO_ROLE_ID, BRO_ROLE_ID, AGENT_ROLE_ID, STATUS_LIST } from "../../config/constant"
import * as HELPER from './../../config/helper';

const PayoutDetails = (props) => {
    // const routeParams = useParams()

    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const commonRedux = useSelector((state) => state.common);
    const [refresh, setRefresh] = useState(false);
    const [searchFilters, setSearchFilters] = useState({page_no: 1,pagination: true});
    const [searchKey, setSearchKey] = useState();
    const [apiResponse, setApiResponse] = useState({});
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    const [isMounted, setIsMounted] = useState(false);
    // const [pageName, setPageName] = useState('');
    const [urlQueryParams, setUrlQueryParams] = useState({});

    let { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    

    // All PAYOUT DETAILS Data
    useEffect(() => {
        if (
            (queryParams.get('month') && isNaN(new Date(queryParams.get('month')))) || 
            (!queryParams.get('role_type') || !queryParams.get('role_id') || !queryParams.get('status') || !queryParams.get('month') || !queryParams.get('type')) ||
            (queryParams.get('role_type') &&  Number(HELPER.GET_USER_ROLE(queryParams.get('role_type'))) !== Number(queryParams.get('role_id'))) ||
            (queryParams.get('role_type') && !HELPER.GET_USER_ROLE(queryParams.get('role_type'))) 
        ) {
            history("/not-found");
            return false;

        } else {
            setIsMounted(true)
            
            getUrlParams()

        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * GET QUERY STRING URL PARAMS
     */
    const getUrlParams = () => {
    
        let queryParamsObj = {}
        //LOOP THROUGH URL PARAMS
        for(const [urlParamKey, value] of queryParams) {
            if(urlParamKey === 'type'){
                if( value !== "all"){
                    queryParamsObj['type'] = value;
                }
            }else if (urlParamKey === 'scheme_id' && typeof value == 'string') {
                queryParamsObj['scheme_id'] = value.split(',').map(Number);
            } else if(urlParamKey === 'business_line' && typeof value == 'string'){
                let arr = value.split(',')?.map(value => value.trim())?.filter(value => value !== '');  
                if(arr?.length>0){
                    queryParamsObj['business_line'] = arr;
                }  
            }else{
                queryParamsObj[urlParamKey] = value;
            }
        }
        //SET URL PARAMS OBJ & CALL SERVICE
        setUrlQueryParams(queryParamsObj)
        componentDidMount(queryParamsObj)

    }


    const componentDidMount = (filters={}) => {
        let updatedSeacrFilter = Object.assign({}, filters)
        
       
        dispatch(CommonActions.checkedAllStatus(false));
        dispatch(CommonActions.updateApproveButtonStatus(false));
        dispatch(CommonActions.getMultiSelectedLoans([]));
        dispatch(CommonActions.setExcludeMultiSelectedSfa([]));
        
        setSearchFilters(updatedSeacrFilter)
        getDashboardList(updatedSeacrFilter)
    }


    //FOR RESET SEARCH BOX
    const resetSearchFilter = () => {
        setRefresh(refresh + 1);
        setSearchKey('');
    }

    /**
     * PAYOUT DETAILS API
     * @param {*} param 
     */

    const getDashboardList = (param = {}) => {
        
        if(typeof param.status !== "undefined" ){
            if(isNaN(+param.status)){
                param['status'] = STATUS_LIST[param.status]
            }
        }
        
        let updatedSearchParams = param


        let paramOb = {};
        //IF ALL SELECTED REMOVE FROM REQUEST PARAMS

        if (updatedSearchParams.type && updatedSearchParams.type.toLowerCase() === 'all') {
            delete updatedSearchParams['type']
            paramOb = updatedSearchParams
        }
        else {
            paramOb = updatedSearchParams//{ ...searchFilters, ...param };
        }

        //REMOVE UNWANTED FILTERS BASIS OF ROLE
        if ([ARO_ROLE_ID, BRO_ROLE_ID].includes(paramOb.role_id)) {
            delete paramOb["level"]
        } else if ([AGENT_ROLE_ID].includes(paramOb.role_id)) {
            delete paramOb["tagging"]
        }

        if (typeof paramOb["role_id"] !== "undefined" && +paramOb["role_id"] === 0) {
            delete paramOb["role_id"]
        }

        if (typeof paramOb["type"] !== "undefined" && paramOb["type"] === 'all') {
            delete paramOb["type"]
        }
        //DEL REF FOR RESET FILTER
        if (paramOb.reset) {
            delete paramOb.reset
        }

        if(typeof paramOb.page_no == "undefined"){
            paramOb.page_no = 1;
        } 

        setSearchFilters(paramOb);
        setLoading(true)

 
       
        if(commonRedux && (!commonRedux.checkedallStatus || commonRedux.checkedallStatus!==true)){
            dispatch(CommonActions.updateApproveButtonStatus(false));
            //FOR MULTISELECTED USERS
            dispatch(CommonActions.getMultiSelectedLoans([]));
            dispatch(CommonActions.setExcludeMultiSelectedSfa([]));
        }
        
        dispatch(CommonActions.payoutDetails(paramOb)).then(res => {
            setLoading(false)

            if (res.status === 200 && res.data && Object.keys(res.data).length) {
                setApiResponse(res.data);

            } else {
                setApiResponse(res.data);

            }
        });

    };

    /**
     * RESET FILTER
     */
    const resetFilters = (params = {}) => {
        let updatedFilters = { status: 0, page_no: 1, role_type: queryParams.get('role_type') || "agent", role_id: 0, reset: true, ...params };

        //RESET BUSINESSTYPE TAB
        componentDidMount(updatedFilters)

    }

    /**
     * SET FILTERS FROM PAGEHEADER COMPONENT
     * @param {*} params 
     */
    const setFilter = (params) => {
        setSearchKey('');
        if (params.search_key && params.search_key.trim()) {
            setSearchKey(params.search_key);
        }

    }

    const getPayoutDetailsList = (params) => {
        getDashboardList(params)
    }


    /**
     * ON SEARCH BUTTON HANDLER
     * @param {*} params 
     */
    const getSearchFilterData = (params) => {

        delete params['search_key']
        delete searchFilters['search_key']
        if (searchKey) {
            params['search_key'] = searchKey;
        }
        
        componentDidMount(params)

    }


    const handleTypeClick = (params, action, typeName) => { 
        let statusType = {
            0: 'Pending',1:'Approved',2:'Rejected',3:'Paid',4:'Onhold'
        }
        dispatch(CommonActions.pageStatusType(statusType[params.status]));
        componentDidMount(params);
    }
    
    return (
        isMounted ? <React.Fragment>
            <FullPageLoader show={loading} />

            <div className="dashboard-outer">
                <div className="container-fluid">
                    <div className="top-heading">
                        <h1>{t("COMMON.INCENTIVE_DASHBOARD")}</h1>
                       
                    </div>
                    <div className="loan-filter-bg dashboard-filter-top">
                        {/* FILTERS AND SEARCH - RESET  BUTTON */}
                        <LeadListFilter action="payoutDetails"
                            resetFilters={resetFilters}
                            setSearchFilters={setSearchFilters}
                            getDashboardList={getSearchFilterData}
                            resetSearchFilter={resetSearchFilter}
                            searchFilters={JSON.parse(JSON.stringify(searchFilters))}
                            urlQueryParamsRef={JSON.parse(JSON.stringify(urlQueryParams))}

                        />
                    </div>

                    <PageHeader action="payoutDetails"
                        title={t('COMMON.PAYOUT_DETAILS')}
                        showActionButtons={true}
                        refresh={refresh}
                        getDashboardList={getDashboardList}
                        setFilter={setFilter}
                        routeParams={JSON.parse(JSON.stringify(queryParams))}
                        setLoading={setLoading}
                        searchFilters={JSON.parse(JSON.stringify(searchFilters))} 
                        apiResponse={apiResponse}
                    />

                    <div className="payout-summary-tabs">
                        <Tab.Container id="left-tabs-example" defaultActiveKey={'all'} >
                            {/* MAIN HEADING */}
                            <Nav variant="pills" className="flex-column tab-line" >
                                {/* <div className="tab-list">
                                    {

                                        apiResponse && Object.keys(apiResponse).length > 0 &&
                                        apiResponse.total_counts && apiResponse.total_counts.map((item, index) => {

                                            return (<Nav.Item key={item.key} id={item.key} onClick={() => handleTypeClick({ ...searchFilters, type: item.key, page_no: 1 }, 'Status', item.key)}>
                                                <Nav.Link className={((!searchFilters.type && item.key.toLowerCase() === "all") || (searchFilters.type && searchFilters.type.toLowerCase() === item.key.toLowerCase())) ? "active" : ""} >
                                                    {item.key} ({item.value})
                                                </Nav.Link>
                                            </Nav.Item>)
                                        })
                                    }
                                </div> */}
                                {
                                    apiResponse && (Object.keys(apiResponse).length > 0 && apiResponse.paid && Object.keys(apiResponse.paid).length)
                                    && <PaidAmount amount={apiResponse.paid} action="payoutDetails" />
                                }

                            </Nav>
                            {/* {
                                apiResponse && Object.keys(apiResponse).length > 0 &&
                                apiResponse.total_counts.map((item, index) => {

                                    return ( */}
                                    <Tab.Content key={'all'} id={'all'}>
                                        <Tab.Pane eventKey={'all'}>
                                            <div className='approve-status-tabs'>
                                                <Tab.Container id="left-tabs-example">

                                                    <Nav variant="pills" className="flex-column tab-bg">
                                                        <div className="tab-list">
                                                            {
                                                                apiResponse?.statusList?.length &&
                                                                apiResponse.statusList?.map((ob, index) => {

                                                                    return (
                                                                        // SUB HEADING
                                                                        <Nav.Item key={ob.key} id={ob.key}>
                                                                            <Nav.Link onClick={() => handleTypeClick({ ...searchFilters, status: ob.status_id, page_no: 1 }, 'typeStatus', ob.key)} active={((!searchFilters.status && !ob.status_id) || (searchFilters.status > 0 && +searchFilters.status === ob.status_id) || (ob.key === searchFilters.status)) ? "active" : ""}>
                                                                                {t("DASHBOARD." + ob.key)}({ob.value})
                                                                            </Nav.Link>
                                                                        </Nav.Item>


                                                                    )

                                                                })
                                                            }
                                                        </div>
                                                    </Nav>
                                                    {/* PAYOUT TABLE */}
                                                    <PayoutDataTable apiResponse={apiResponse} routeParams={queryParams} getPayoutDetailsList={getPayoutDetailsList} loading={loading} key={'All'} searchFilters={searchFilters} searchKey = {searchKey}/>
                                                </Tab.Container>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                     {/* )
                                 })
                             } */}
                        </Tab.Container>

                    </div>

                </div>
            </div>
        </React.Fragment> : ""
    );
};

export default PayoutDetails;
