import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InputField from '../../common/InputField';
import { CommonActions } from "../../../store/action";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import FullPageLoader from "../../common/FullPageLoader";
import ConfirmationModal from "../../common/ConfirmationModal";
import CurrencyInputField from "../../common/CurrencyInputField";
import * as HELPER from "../../../config/helper";
import AgentPointsHistory from "./AgentPointHistory";
import Pagination from "../../common/Pagination";
import AgentPointExport from "./AgentPointExport";

const AgentPointConfigTable = (props) => {
    const { t } = useTranslation("common");
    const [categoryData, setCategoryData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [tempErrors, setTempErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const commonRedux = useSelector((state) => state.common);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [actionId, setActionId] = useState();
    const [viewTimeLine, setViewTimeLine] = useState(false);
    const [paginationData, setPaginationData] = useState({});
    const [agentId, setAgentId] = useState(null)
    const [exportedPopUp, setExportedPopUp] = useState(false);

    useEffect(() => {
        if(props.categoryDetails){
            setCategoryData(props.categoryDetails?.data);
            setPaginationData(props.categoryDetails?.pagination)
        }
    }, [props]);

 

    const saveCategoryConfig = async(data) =>{
        if(data.response){
            setLoading(true)
            CommonActions.saveCategoryDetails({configData : categoryData}).then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    props.getDealerConfigDetails();
                    toast.success(res.message);
                } else {
                    setCategoryData(commonRedux.dealerCategoryConfig.data);
                    toast.error(res.message);
                }
            }).catch(err => {
                setLoading(false)
                toast.error(err)
            });
        }
        setShowConfirmation(false);
    } 

    const showModalViewTimeLine = (action, id) => {
        setActionId(id);
        setViewTimeLine(action);
        document.body.classList.remove("overflow-hidden");

        if (action) {
            document.body.classList.add("overflow-hidden");
        }
    };

    const runPaginationFunc = () => { };

    const handleRequestData = (params) => {
        props.getDealerConfigDetails(params); 
    };

    const showModalExportedPopUp = (id) => {
        setExportedPopUp(true)
        setAgentId(id);
        document.body.classList.add("overflow-hidden");
    }

    const hideModalExportedPopUp = () => {
        setExportedPopUp(false)
        setAgentId(null);
        document.body.classList.remove("overflow-hidden");
    }
 

    return (
        <>
            <div>
            <FullPageLoader show={loading} />
                <div className="agent-point-config-table bg-white">
                    <div className="data-table ">
                        <table>
                            <thead>
                                <tr> 
                                    <th>{t("AGENT_POINT_CONFIG.SFA_ID")}</th>
                                    <th>{t("AGENT_POINT_CONFIG.USER_ROLE")}</th>
                                    <th>{t("AGENT_POINT_CONFIG.USER_NAME")}</th>
                                    <th>{t("AGENT_POINT_CONFIG.TOTAL_DISB_AMOUNT")}</th>
                                    <th>{t("AGENT_POINT_CONFIG.TOTAL_LOAN_IDS")}</th>
                                    <th>{t("AGENT_POINT_CONFIG.CURRENT_POINTS")}</th>
                                    <th>{t("AGENT_POINT_CONFIG.REEDEEMABLE_POINTS")}</th>
                                    <th>{t("AGENT_POINT_CONFIG.ACTION")}</th>  
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    categoryData?.length ? 
                                    categoryData.map((point, index) => (
                                        <React.Fragment  key={point.agent_id}>
                                    <tr className={"active"}>
                                        <td>{point.agent_id}</td>
                                        <td>Agent</td>
                                        <td>{point.name}</td> 
                                        <td>{HELPER.addCurrencyFormatting(point.total_disbursed)}</td> 
                                        <td>{point.total_lead}</td> 
                                        <td>{(+point.total_points - +point.redeem_points)?.toFixed(2) }</td> 
                                        <td>{point.redeem_points}</td> 
                                        <td>  
                                            <ul className="action-btn">
                                                <li className="" title="History"> 
                                                    <i
                                                        className="ic-history"
                                                        onClick={() => showModalViewTimeLine('LIST', point.agent_id)}
                                                    ></i>
                                                </li>
                                                <li className="" title="Export">
                                                    <i className="ic-export"
                                                     onClick={() => showModalExportedPopUp(point.agent_id)}
                                                    ></i>
                                                </li> 
                                            </ul>
                                        </td> 
                                          
                                    </tr>
                                    </React.Fragment>
                                    ))
                                    :  (loading) ? <tr><td>&nbsp;</td></tr> : <tr><td colSpan={8} className="no-data"> {t('DEALER.NO_DATA_FOUND')}</td></tr>
                                    
                                }
                            </tbody>
                          
                            
                        </table> 
                    </div>


                    <div className="pagination-outer">
                        {!loading && paginationData && paginationData.total ? <Pagination
                            paginateData={{
                                page : 1,
                                runPaginationFunc,
                                loading: loading,
                                tableData: [],
                                totalLengthWithoutPagination: 0,
                                paginationData,
                                filterData: props.searchFilters,
                            }}
                            action={"payoutDetails"}
                            sendRequestDataToParent={handleRequestData}
                        /> : ''}
                    </div>
                </div>

                {viewTimeLine &&
                    <AgentPointsHistory
                        viewTimeLine={(viewTimeLine !== '')?true:false}
                        id={actionId}
                        type={viewTimeLine}
                        showModalViewTimeLine={showModalViewTimeLine}
                    />
                }

                {showConfirmation ? (
                    <ConfirmationModal
                        confirmationData={{
                            show: showConfirmation,
                            confirmationText: t("DEALER.SURE_TO_SAVE_CONFIG")
                        }}
                        confirmationModalResponse={(data) =>
                            saveCategoryConfig(data)
                        }
                    />
                ) : null}
                {
                    exportedPopUp && agentId && <AgentPointExport show={exportedPopUp} agent_id={agentId} handleClose={hideModalExportedPopUp} />
                } 
            </div>
        </>
    );
};

export default AgentPointConfigTable;
