import React from 'react' 
import { useTranslation } from "react-i18next";
import ProgressBarLabel from "./common/ProgessBar";
import * as HELPER from './../../config/helper';

function PaidAmount(props) {
    const { t } = useTranslation("common");
    let { total_payout, total, allowance_payout,total_allowance, incentive_payout, total_incentive } = props.amount
    return (
        <div className="payout-summery-right-panel">
            <ul>
                <li>
                    <span className="total-payout-txt"> {HELPER.addCurrencyFormatting(total)}</span>
                    <label>{t("COMMON.TOTAL_PAYOUT")}</label>
                    {/* {props.action === "dashboard" ? ( */}
                        <ProgressBarLabel amount ={{total_amount: total, paid_amount: total_payout}}/>
                    
                         {/* <span className="paid-rp-txt"> (Paid {HELPER.addCurrencyFormatting(amount.total_payout)})</span> */}
                    
                </li>
                <li className="equal-sign">=</li>
                <li>
                    <span className="total-incentives-txt"> {HELPER.addCurrencyFormatting(total_incentive)}</span>
                    <label>{t("COMMON.TOTAL_INCENTIVES")}</label>
                    {/* <span className="paid-rp-txt"> (Paid {HELPER.addCurrencyFormatting(amount.total_incentive)})</span> */}
                    <ProgressBarLabel amount={{total_amount: total_incentive, paid_amount: incentive_payout}}/>

        </li>

        <li className="plus-sign">+</li>
        <li>
            <span className="total-allowance"> {HELPER.addCurrencyFormatting(total_allowance)}</span>
            <label>{t("COMMON.TOTAL_ALLOWANCE")}</label>
            {/* <span className="paid-rp-txt"> (Paid {HELPER.addCurrencyFormatting(amount.total_allowance)})</span> */}
            <ProgressBarLabel amount={{total_amount: total_allowance, paid_amount: allowance_payout}} />
        </li>
    </ul>
</div>
    )
}

export default PaidAmount