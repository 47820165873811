import React, { useEffect,useState} from 'react'
import Modal from "../../common/Modal";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"; 
import { IncentiveService } from "../../../services";
import { toast } from "react-toastify";
import FullPageLoader from '../../common/FullPageLoader';
import * as HELPER from '../../../config/helper'; 


function IfDataExport(props) { 
    const [buttonDisable, setButtonDisable] = useState(true);
    const [leadData, setLeadData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation("common");
    const commonRedux = useSelector((state) => state.common);
    
    const ifheaders = [
        { label: 'SFA Id', key: "id" },
        { label: 'User Role', key: "user_role" },
        { label: 'User Name', key: "user_name" },
        { label: 'Sfa Status', key: "sfa_status" },
        { label: 'Incentive Type', key: "incentive_type" },
        { label: 'Target', key: "target" },
        { label: 'Achieve', key: "achieve" },
        { label: 'Incentive Amount', key: "incentive_amount" },
    ];
    
 
    useEffect(() => {
        let params= commonRedux.ifPayoutReqParams ? JSON.parse(JSON.stringify(commonRedux.ifPayoutReqParams)) : {};
         params.pagination=false;  
        exportDealerdata(params);
        // eslint-disable-next-line
    },[])

    const exportDealerdata = (params) => {
        setLoading(true); 
        let updatedUsers = commonRedux.getMultiselectedPayouts;
 
        //CHECK UNSELECTED IDs & FILTER FROM ALL SELECTED PENDING RECORDS
        if(commonRedux && commonRedux.getMultiselectedExcludedPayouts && commonRedux.getMultiselectedExcludedPayouts.length){
            updatedUsers = updatedUsers.filter(users=> !commonRedux.getMultiselectedExcludedPayouts.includes(users) )
        } 
         
        IncentiveService.getIfPayoutList(params).then(function (response) {
            setLoading(false);
            if (response.data.status === 200) { 
                if(response.data.data && response.data.data.data){
                  let totaldata=response.data.data.data;
                  if(updatedUsers?.length){
                    totaldata = totaldata?.filter((dt) => updatedUsers.includes(dt.user_id));
                  }
                  
                    if(totaldata.length>0){
                        setButtonDisable(false);
                        let leadArray = [];
                        for(let i=0;i<totaldata.length;i++){ 
                            let userRecord = totaldata[i]; 
                            userRecord.ifam.forEach((ob)=>{ 
                                let leadRecord = {
                                    id: userRecord.user_id,
                                    user_role: userRecord.role,
                                    user_name: userRecord.user_name,
                                    sfa_status: userRecord.user_status == 1 ? 'Active' : 'Inactive',
                                    incentive_type: ob?.ifst?.scheme_name,
                                    target : ob.target,
                                    incentive_amount: (HELPER.numberFormat(ob.amount)),
                                    achieve: ob.achieve_target 
                                };
                                leadArray.push(leadRecord);
                            });
                        }   
                        setLeadData(leadArray);
                    }
                }
            } else {
                setLoading(false);
                toast.error(response.data.message);
            }
        }).catch(function () {
            setLoading(false);
        });
    };
    return (
        <div className="model-popup-outer confirmation-popup">
            <FullPageLoader show={loading} />
            <Modal show={props.show} handleClose={props.handleClose} >
                <div className="modal-header">
                    <h2>{t('DETAIL.DATA_EXPORT_AS')}</h2>
                </div>
                <div className="modal-body" >
                    <div className='confirmation-btn'>
                        <CSVLink data={leadData} headers={ifheaders} filename={"if-payout-data.csv"}>
                            <button className='btn-primary' disabled={buttonDisable}>{t('FORM.IF_DATA')}</button>
                        </CSVLink> 
                        
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default IfDataExport;
