import MasterService from './MasterService';


export const IncentiveService = {
    ...MasterService,
    
    getIncentiveConfig(body){ 
        return this.get("incentive/incentiveConfig",body)
    },
    getAllIncentive(body){ 
        return this.post("incentive/getAllIncentive",body)
    },
    getIncentiveSchema(body){ 
        return this.post("incentive/incentiveSchemes",body)
    },
    getSchemeArea(body){ 
        return this.post("incentive/schemeArea",body)
    },
    saveIncentive(body){ 
        return this.post("incentive/saveIncentive",body)
    },
    deleteBanner(body){ 
        return this.post("incentive/deleteBanner",body)
    },
    updateStatus(body){ 
        return this.post("incentive/updateIncentiveStatus",body)
    },
    getFinancierlist(body){ 
        return this.get("incentive/getFinancierList",body)
    },
    getRoleList(body){ 
        return this.get("incentive/getRoleList",body)
    },
    getUserList(body){ 
        return this.post("user/get-users",body)
    },
    getRoleProducts(body) {
        return this.get("incentive/getRoleProductMappingData",body)
    },
    getAdminDashboard(body) {
        return this.post("userAdmin/admindashboard",body)
    },
    getPayoutDetails(body) {
        return this.post("userAdmin/payoutDetails",body)
    },
    getCsvUploadHistory(body){
        return this.get("userAdmin/getCsvHistory",body)
    },
    getIncentiveHistoryList(body){
        return this.post("userAdmin/getIncentiveHistory",body)
    },
    saveIncentiveHistory(body){ 
        return this.post("userAdmin/saveIncentiveHistory",body)
    },
    saveIncentiveUserData(body){
        return this.post("userAdmin/saveIncentiveUserData",body)
    },
    updateUserAmountStatus(body){
        return this.post("userAdmin/updateStatus",body)
    },
    updateIncentiveAmount(body){
        return this.post("userAdmin/updateIncentiveAmount",body)
    },
    getUserIncentiveLeadList(body) {
        return this.post("userAdmin/getUserIncentiveLeadList",body)
    },
    getReportingRoleManager(body) {
        return this.post("incentive/userListByRole",body)
    },
    deleteIncetiveDetails(body) {
        return this.post("incentive/removeIncetiveDetail",body)
    },
    deleteFinancierDetails(body) {
        return this.post("incentive/removeFinancierDetail",body)
    },
    leadCountByRole(body) {
        return this.post("userAdmin/leadCountByRole",body)
    },
    getPdfUploadDtls(body){
        return this.post("userAdmin/checkIfPdfUploaded",body)
    },
    getDealerDetails(body){
        return this.post("dealerIncentive/getDealerIncentive",body)
    },
    updateDealerStatus(body){
        return this.post("dealerIncentive/updateDealerStatus",body)
    },  
    getDealerConfig(body){
        return this.post("dealerIncentive/getDealerConfig",body)
    },
    getDealerHistory(body){
        return this.post("dealerIncentive/getDealerHistory",body)
    },
    submitDealerBulkUpload(body){
        return this.post('dealerIncentive/dealerBulkUpload', body)
    },
    updateDealerAmount(body){
        return this.post('dealerIncentive/updateDealerAmount', body)
    },
    getLoanIdList(body){
        return this.post("userAdmin/getLoanIdList",body)
    },
    updatePpdStatus(body){
        return this.post("userAdmin/updatePpdStatus",body)
    },

    uploadPpdCSV(postData, headers) {
        return this.post('userAdmin/uploadPpdCSV', postData, headers)
    },
    getDealerCategoryDetails(body) {
        return this.post('dealerConfig/getDealerCategoryData', body)
    },
    saveCategoryDetails(body) {
        return this.post('dealerConfig/saveCategoryDetails', body)
    },
    getFinancierConfigGroup(body){
        return this.post('dealerConfig/getFinancierConfigGroup', body)
    },
    saveFinancierConfig(body) {
        return this.post('dealerConfig/saveFinancierConfig', body)
    },
    getDeviationLimitConfig(body){
        return this.post('dealerConfig/getDeviationLimitConfig', body)
    },
    saveDeviationConfig(body) {
        return this.post('dealerConfig/saveDeviationConfig', body)
    },
    getSchemeAreaList(body){ 
        return this.post("dealerConfig/schemeAreaList",body)
    },
    getDealerCaculation(body){ 
        return this.post("dealerConfig/dealerCalculation",body)
    },
    getTargetListing(body){ 
        return this.post("inventoryFunding/targetListing",body)
    },
    getIfPayoutList(body) {
        return this.post("inventoryFunding/getPayoutList",body)
    },
    getIfIncentiveHistoryList(body){
        return this.post("inventoryFunding/getIfIncentiveHistory",body)
    },
    updateIfIncentiveAmount(body){
        return this.post("inventoryFunding/updateAmount",body)
    },
    getIfPayoutDetails(body) {
        return this.post("inventoryFunding/getpayoutDetail",body)
    },
    getAgentPointDetails(body){ 
        return this.post("pointConfig/agentPointList",body)
    }, 
    getAgentRedeemPoint(body){ 
        return this.post("pointConfig/agentRedeemPoint",body)
    },
}

