import React, { useEffect, useState } from "react";
import Modal from "../../common/Modal";
import { useSelector, useDispatch } from "react-redux";
import { CommonActions, IncentiveActions } from "../../../store/action";
import CurrencyInputField from "../../common/CurrencyInputField";
import ConfirmationModal from "../../common/ConfirmationModal";
import Pagination from "../../common/Pagination";
import * as HELPER from "../../../config/helper"; 
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import FullPageLoader from "../../common/FullPageLoader";
import DateFormat from 'dateformat';
import { BRO_ALLOWANCE,ARO_ALLOWANCE,ARCO_ALLOWANCE,AGENT_ROLE_ID ,ALLOWANCE_ARRAY} from "../../../config/constant"; 
import { useNavigate } from 'react-router-dom';
import InventoryViewDetail from "./InventoryViewDetail";
import IfPayoutHistoryDetails from "./IfPayoutHistoryDetails";

const IfDashboardListTable = (props) => { 
    const { t } = useTranslation("common");
    const history = useNavigate();
    const [tabelShow, setTabelShow] = useState(false);
    const commonRedux = useSelector((state) => state.common);

    const [multiSelectedLoans, setMultiSelectedLoans] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
   
    const dispatch = useDispatch();

    const [viewTimeLine, setViewTimeLine] = useState(false);
    const [subTableAcceptReject, setSubTableAcceptReject] = useState({
        status: false,
        msg: "",
    });
    const [showInputSave, setShowInputSave] = useState({ loanId: 0, key: "" });

    const [loading, setLoading] = useState(props.loading);
    const [paginationData, setPaginationData] = useState({});

    const [tableData, setTableData] = useState([]);
    const [selectAllDisabled, setSelectAllDisabled] = useState(false);
    const [actionId, setActionId] = useState();  
    const [multiExcludedSfa, setMultiExcludedSfa] = useState([]); 

 
    useEffect(() => { 
        if(commonRedux && commonRedux.approveButton && commonRedux.approveButton===true){
           setCheckedAll(true);
        }else{
           setCheckedAll(false);
            setMultiSelectedLoans([]);
        } 
        filterTableData(props.apiResponse.data?.data);
        // setTableData(props.apiResponse.data?.data);
        setPaginationData(props.apiResponse.pagination)

        setShowInputSave({ loanId: 0,key: "" })
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.apiResponse])
    /**
     * TOGGLE SUB TABLE
     * @param {*} LoandId
     */
    
    const toggleTable = (id) => {
        setTabelShow(0); 
        if (tabelShow !== id) { 
             setTabelShow(id);
        }
    };
 

    /**
     * HANDLE CHECKBOX CHECK/UNCHECK
     * @param {*} e 
     * @param {*} list 
     */
    const handleSingleCheckboxChange = async (e, list) => {

        let getMultiSelectedpayouts = multiSelectedLoans; 

        if (e.target.checked) {
            if (
                multiSelectedLoans.length + 1 ===
                commonRedux.ifPayoutDetails.data.length
            ) {
                setCheckedAll(true);  
                dispatch(CommonActions.checkedAllStatus(props.searchKey ? false : true));
            }
            getMultiSelectedpayouts = [...getMultiSelectedpayouts, list.user_id]
            setMultiSelectedLoans(getMultiSelectedpayouts);
            dispatch(CommonActions.updateApproveButtonStatus(true));

            setMultiExcludedSfa( multiExcludedSfa.filter(user=> +user !== +list.user_id ) )

        } else {
            setCheckedAll(false);
            dispatch(CommonActions.checkedAllStatus(false));
            let newSelctedId = multiSelectedLoans.filter((ob) => ob != list.user_id); // eslint-disable-line
            //CREATE ARRAY OF IDs WHICH ARE DESELCTED
            getMultiSelectedpayouts = newSelctedId

            setMultiSelectedLoans(getMultiSelectedpayouts);

            let status = newSelctedId.length ? true : false;
            dispatch(CommonActions.updateApproveButtonStatus(status));

            setMultiExcludedSfa((prev)=> { return [...prev, list.user_id] })
        }

        // props.multiSelectedLoans(multiSelectedLoans)
        dispatch(CommonActions.getMultiSelectedLoans(getMultiSelectedpayouts));

    };



   

    
    /**
     * HANDLE TIMELINE POPUP
     * @param {*} action
     */
    const showModalViewTimeLine = (action, id) => {
        setActionId(id);
        setViewTimeLine(action);
        document.body.classList.remove("overflow-hidden");

        if (action) {
            document.body.classList.add("overflow-hidden");
        }
    };

    const handleSubTableAcceptReject = (params) => {
        let { status } = params;

        setSubTableAcceptReject(params);
        document.body.classList.remove("overflow-hidden");
        if (status) {
            document.body.classList.add("overflow-hidden");
        }

    };

    const confirmationModalResponse = (params) => { 
        handleSubTableAcceptReject(params)
    }

    const handleRequestData = (params) => {
        props.getPayoutDetailsList(params); 
    };
    
    const showDetailedView = (item) => {
        let fullUrl = `/if-incentive-details?id=${item?.id}`;  
        if(item?.role){
            fullUrl += `&role=${item?.role}`;
        } 
        if(item?.user_name){
            fullUrl += `&user_name=${item?.user_name}`;
        } 

        if(item?.user_id){
            fullUrl += `&user_id=${item?.user_id}`;
        }
        
        history(fullUrl); 

    };

    const runPaginationFunc = () => { };

    const selectAll = (e,statusType) => {
        let selectedId = [] 
        if(commonRedux.ifPayoutDetails && commonRedux.ifPayoutDetails.data &&  commonRedux.ifPayoutDetails.data.length){
            selectedId =  commonRedux.ifPayoutDetails.data.map(ob=>ob.user_id);
        } 
        if (e.target.checked) {
            setMultiSelectedLoans(selectedId);
            setCheckedAll(true);

            dispatch(CommonActions.getMultiSelectedLoans(selectedId));
            setMultiExcludedSfa([]) 
            dispatch(CommonActions.checkedAllStatus(props.searchKey ? false : true)); 
            dispatch(CommonActions.pageStatusType(statusType));
        } else {
            setMultiSelectedLoans([]);
            setCheckedAll(false);
            dispatch(CommonActions.getMultiSelectedLoans([]));
            dispatch(CommonActions.checkedAllStatus(false)); 
            dispatch(CommonActions.pageStatusType(''));
        }
        dispatch(CommonActions.updateApproveButtonStatus(e.target.checked));

    };
   

    // Function to merge the 'Additional Incentive- Phasing' schemes
    const filterTableData = (userData) => {
        if(userData?.length){
            userData.forEach(user => {
                let totalAmount = 0;
                let latestEntry = null;
                let otherSchemes = [];
    
                user.ifam.forEach(scheme => {
                    if (scheme.ifst?.scheme_name === "Additional Incentive- Phasing") {
                        totalAmount += scheme.amount;
     
                        if (!latestEntry || new Date(scheme.if_date) > new Date(latestEntry.if_date)) {
                            latestEntry = scheme;
                        }
                    } else { 
                        otherSchemes.push(scheme);
                    }
                }); 
                if (latestEntry) { 
                    latestEntry.amount = totalAmount; 
                    user.ifam = [latestEntry, ...otherSchemes];
                }
            });
        } 
        setTableData(userData); 
    }
   
    useEffect(()=>{
        //UPDATE DESELCTED USERs IN REDUX
        dispatch(CommonActions.setExcludeMultiSelectedSfa(multiExcludedSfa))
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiExcludedSfa])
    
    const userInfo = useSelector((state) => state.auth && state.auth.authUser && state.auth.authUser.user_data);  
     const isApproveReject = (userInfo && userInfo.config && userInfo.config.PAYOUT_PAGE &&  userInfo.config.PAYOUT_PAGE.hasOwnProperty('APPROVE_REJECT')) ? true : false;

    const page_status =  commonRedux?.page_status || "active"; 
  
    return (
        <>
            <div>
                <FullPageLoader show={loading} />
               
                <div className="if-detail-table">
                    <div className="data-table"> 
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>
                                        {
                                            tableData?.length > 0  && 
                                            <li className="custom-control custom-checkbox">
                                                { <input
                                                    id="sli0"
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    onChange={(e) => selectAll(e,tableData[0]['status_name'])}
                                                    checked={checkedAll}
                                                    disabled={selectAllDisabled || !isApproveReject}
                                                />}
                                                <label
                                                    htmlFor="sli0"
                                                    className="custom-control-label"
                                                ></label>
                                            </li>


                                        }
                                        SFA ID

                                    </th>
                                    <th>User Role</th>
                                    <th>Name</th>
                                    <th>SFA Status</th> 
                                    <th>
                                        Net Amount
                                        <div className="info-icn m-xs-l">
                                            <i className="ic-info-icon1"></i>

                                            {/* <div className="info-text-detail">
                                                Net Amount = Approved Amount + BPJS + Salary Arrear - Deduction
                                            </div> */}
                                        </div>
                                    </th> 
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    // tableHtml
                                    tableData?.length > 0 ?
                                        tableData?.map((item) => (
                                            <React.Fragment  key={item.id}>
                                                <tr
                                                    className={tabelShow === item.id ? "active" : ""}
                                                    key={item.id}
                                                >
                                                    <td onClick={() => toggleTable(item.id)}>
                                                        {" "}
                                                        <span className="loan-id-arrow"></span>
                                                    </td>
                                                    <td>
                                                        <li className="custom-control custom-checkbox">
                                                            <input
                                                                id="sli0"
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                onChange={(e) =>
                                                                    handleSingleCheckboxChange(e, item)
                                                                }
                                                                checked={(checkedAll)? checkedAll: multiSelectedLoans.includes(item.user_id)}
                                                                disabled={
                                                                    (isApproveReject) ? false : true
                                                                }
                                                            />
                                                            <label
                                                                htmlFor="sli0"
                                                                className="custom-control-label"
                                                            ></label>
                                                        </li>
                                                        {item.user_id}
                                                    </td>
                                                    <td>
                                                        <span className="truncate" title={item.role}>{item.role}</span>
                                                    </td>
                                                    <td>
                                                        <span className="truncate" title={item.user_name}>{item.user_name}</span>
                                                    </td>
                                                    <td>
                                                        {
                                                            +item.user_status === 1
                                                            ?
                                                                <span className="active-status"> 
                                                                    Active
                                                                </span>
                                                            :
                                                                <span className="inactive-status">
                                                                   Inactive
                                                                </span>
                                                        }
                                                    </td>  
                                                   
                                                <td className="">
                                                    {HELPER.addCurrencyFormatting(item.net_amount)}
                                                </td> 
                                                <td>
                                                    <ul className="action-btn">
                                                        {/* <li className="" title="History">
                                                            <i
                                                                className="ic-history"
                                                                onClick={() => showModalViewTimeLine('LIST', item.id)}
                                                             ></i>
                                                        </li> */}
                                                        <li className="">
                                                            <i onClick={() => showDetailedView(item)} className="ic-remove_red_eyevisibility"></i>
                                                        </li> 
                                                    </ul>
                                                </td>
                                            </tr>
                                            {
                                                //SUB TABLE HTML
                                                tabelShow === item.id  ? (
                                                    <tr className="subtable" key={"sub" + item.id}>
                                                        <td colSpan="7">
                                                            <div className="wrapper subtable-wrapper">
                                                                <table>
                                                                    <thead>
                                                                        <tr> 
                                                                            <th>Incentive Type</th>
                                                                            <th>Target</th>
                                                                            <th>Achieved</th>
                                                                            <th>Achieved %</th>
                                                                            <th>Incentive Amount</th> 
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        {item.ifam && item.ifam.length ?
                                                                            item.ifam.map((ob, k) => {
                                                                                 
                                                                                return (
                                                                                    <tr key={ob.id} >
                                                                                    <td> {ob.csv_log_id && <i className='ic-file_icn'></i>} {ob.ifst?.scheme_name}</td>  
                                                                                    <td>{ob.target} </td>
                                                                                            <td>
                                                                                               {ob.achieve_target} 
                                                                                            </td>
                                                                                            <td>
                                                                                            {ob.achieve_per}
                                                                                            </td>  
                                                                                        <td>
                                                                                            {HELPER.addCurrencyFormatting(ob.amount)}
                                                                                            {/* <CurrencyInputField
                                                                                                inputProps={{
                                                                                                    id: `amount_${ob.id}_${item.id}`,
                                                                                                    type: "text",
                                                                                                    name: "amount",
                                                                                                    placeholder: " ",
                                                                                                    value: `${ob.amount}`,
                                                                                                    className: `txt-value-input ${showInputSave.key ===
                                                                                                            `amount_${ob.id}_${item.id}`
                                                                                                            ? "hightlighted-txt"
                                                                                                            : ""
                                                                                                            }`,
                                                                                                    disabled: false,
                                                                                                    title : HELPER.addCurrencyFormatting(ob.amount)
                                                                                                    }}
                                                                                                    
                                                                                                    onChange={(e) =>
                                                                                                        handleChangeSchemeAmount(
                                                                                                            e,
                                                                                                            `amount_${ob.id}_${item.id}`,
                                                                                                            ob.id,
                                                                                                            item.id,
                                                                                                            item.user_id
                                                                                                        )
                                                                                                    }

                                                                                                />


                                                                                                { showInputSave.loanId === ob.id &&
                                                                                                    showInputSave.key ===
                                                                                                    `amount_${ob.id}_${item.id}` && (
                                                                                                        <div className="amt-edit-opt">
                                                                                                            <button
                                                                                                                className="btn-primary" 
                                                                                                                onClick={() =>
                                                                                                                    saveSchemeAmountHandler({
                                                                                                                        save: true,
                                                                                                                        item: { ...ob, user_id: item.user_id },
                                                                                                                        type: "amount",
                                                                                                                        amount: ob.amount,
                                                                                                                        parent_id: item.id
                                                                                                                    })
                                                                                                                }
                                                                                                            >
                                                                                                                Save
                                                                                                            </button>
                                                                                                            <i
                                                                                                                className="ic-clearclose"
                                                                                                                onClick={() =>
                                                                                                                    saveSchemeAmountHandler({
                                                                                                                        save: false,
                                                                                                                    })
                                                                                                                }
                                                                                                            ></i>
                                                                                                        </div>
                                                                                                    )} */}
                                                                                            </td> 
                                                                                        </tr>
                                                                                    );

                                                                                })
                                                                                : <tr><td colSpan={5} className="no-data">No Data Found</td></tr>
                                                                            }
                                                                            <tr>
                                                                                <td colSpan={5}>
                                                                                    <div className="p-md float-right">
                                                                                        {item.ifam && item.ifam.length ? <button type="button" className="btn-primary" onClick={() => showDetailedView(item)}>View In Details</button> : null}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                              
                                                                        </tbody>
                                                                       
                                                                </table>
                                                            
                                                            </div>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr key={"sub" + item.id}></tr>
                                                    )
                                                }

                                            </React.Fragment>
                                        ))
                                        :    (loading) ? <tr><td>&nbsp;</td></tr> : <tr><td colSpan={7} className="no-data">No Data Found</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="pagination-outer">
                        {!loading && paginationData && paginationData.total ? <Pagination
                            paginateData={{
                                page : 1,
                                runPaginationFunc,
                                loading: loading,
                                tableData: [],
                                totalLengthWithoutPagination: 0,
                                paginationData,
                                filterData: props.searchFilters,
                            }}
                            action={"payoutDetails"}
                            sendRequestDataToParent={handleRequestData}
                        /> : ''}
                    </div>
                </div> 

                {/* HISTORY */}
                {viewTimeLine &&
                    <IfPayoutHistoryDetails
                        viewTimeLine={(viewTimeLine!=='')?true:false}
                        id={actionId}
                        type={viewTimeLine}
                        showModalViewTimeLine={showModalViewTimeLine}
                    />
                }
                

                {
                    <ConfirmationModal
                        confirmationData={{
                            show: subTableAcceptReject.status,
                            confirmationText: subTableAcceptReject.msg,
                        }}
                        confirmationModalResponse={confirmationModalResponse}
                    />
                }
            </div>
        </>
    );
};

export default IfDashboardListTable;
