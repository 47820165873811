import { IfConstants } from '../constants/if.constants'; 

 
export default function IfReducer(state = {}, action) { 
  switch (action.type) { 
     case IfConstants.IF_SCHEME_LIST_REQUEST: 
      return {
        ...state,
        schemeList: action.payload,
      }; 
    case IfConstants.IF_GET_ROLE_LIST: 
      return {
        ...state,
        roleSchemeList: (state && state.roleSchemeList) ? {...state.roleSchemeList, ...action.payload} : action.payload
      };
    case IfConstants.IF_CONFIG_FILTERS: 
      return {
        ...state,
        incentiveConfig: action.payload,
      }; 
    default:
      return state
  }
}