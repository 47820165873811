import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'; 
import { useTranslation } from 'react-i18next';  
import IfDataExport from './IfDataExport'; 
import IfBulkUploader from "./IfIncentiveBulkUpload";

const PageHeader = (props) => {
   
    const commonRedux = useSelector((state) => state.common) 
    const [exportedPopUp, setExportedPopUp] = useState(false);
    const [teamMember, setTeamMember] = useState('');
    const [dummyBulkuploadUrl, setDummyBulkuploadUrl] = useState({});
    
    const { t } = useTranslation('common'); 

    useEffect(() => {

        setTeamMember('');
    }, [props.refresh])



    useEffect(() => {
        if(commonRedux.incentiveConfig?.sampleFiles && commonRedux.incentiveConfig.sampleFiles.length){
            const filteredArr = commonRedux.incentiveConfig.sampleFiles?.filter(item => item.type === "if_incentive"); 
            
            setDummyBulkuploadUrl(filteredArr?.[0]?.url)
        }
        // eslint-disable-next-line
    }, [commonRedux && commonRedux.incentiveConfig])
 


    const showModalExportedPopUp = () => {
        setExportedPopUp(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideModalExportedPopUp = () => {
        setExportedPopUp(false)
        document.body.classList.remove("overflow-hidden");
    }
    const setSearchkey=(val)=>{
         setTeamMember(val);
         props.setFilter({ search_key: val })
    }

    const apiResponse = (val) => {
        if (val.key === 'Enter') {
            props.getDashboardList({ ...props.searchFilters, search_key: teamMember, page_no: 1 })
        }
    }
 
    let btn_expt_disabled=(commonRedux && commonRedux.ifPayoutDetails && commonRedux.ifPayoutDetails.data && commonRedux.ifPayoutDetails.data.length)?false:true;

    return <>

        <div className="sub-heading p-lg-t p-lg-b">
            <h2>{props.title}</h2>
            <div className="report-heading-right-panel">
                {
                    props.action === 'dashboard' &&
                    <div className="pending-record-txt">
                        <span>{props.pendingRecords} {t('FORM.PENDING_RECORDS')} </span>
                        {t('FORM.FOR_PAST_MONTHS')}
                    </div>
                } 

                <div className='search-bx m-sm-r'>
                    {props.action !== 'dashboard'
                        ?
                        <div className='material'>
                            <input type="text" placeholder='Search by SFA ID, Name, Phone or Email' className='form-input' maxLength={200} value={teamMember}
                                onKeyPress={(e) => apiResponse(e)}
                                onChange={(e) => setSearchkey(e.target.value)}
                            />
                            <i className='ic-search'></i>
                        </div>
                        :
                        ''
                    }
                </div>
            
                    <IfBulkUploader BulkuploadUrl={dummyBulkuploadUrl} searchFilters={props.searchFilters} getDashboardList={props.getDashboardList}/>
          
                {
                   <button className="btn-line btn-export m-sm-l" disabled={btn_expt_disabled} onClick={showModalExportedPopUp}> <i className="ic-export"></i>{t('FORM.EXPORT_DATA')} </ button>
                }
                

            </div>
        </div>
 
        {
            exportedPopUp && <IfDataExport show={exportedPopUp}  handleClose={hideModalExportedPopUp} />
        } 
 
    </>
        ;
}

export default PageHeader