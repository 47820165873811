import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import { DateFormat } from "../../config/helper";


const ReceivedDate = (props) => {

    const [startDate, setStartDate] = useState(null);
    useEffect(() => {
        if (props.element.received_date) {
            setStartDate(new Date(props.element.received_date))
        }
    }, [props.element.received_date])

    const onDateChange = (date) => {
         setStartDate(date)
         if(date){
            props.onChange("received_date", DateFormat(date), '', props.element)
        }else{
            props.onChange("received_date",'', '', props.element)
        }
        
    }

         const { t } = useTranslation('common');
    return (
        <div className={"material animation-effect seller-opt " + (startDate ? 'active-label' : '')}  >
            <div className="datepicker">
                <DatePicker
                    selected={startDate}
                    onChange={(date) => onDateChange(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText={" "}
                    onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                />
                <label data-label={t('FORM.APP_RECEIVED_DATE') + '*'} className={"form-label " + (startDate ? 'top21' : '')}></label>
                {(props.tempErrors && props.tempErrors.received_date) ? <span className="error show">{props.tempErrors.received_date}</span> : <span>&nbsp;</span>}
            
            </div>
        </div>
    );
}

const DisbursedDate = (props) => {
    // const [value, onChange] = useState(new Date());
    const [startDate, setStartDate] = useState(null);

    useEffect(() => {
        if (props.element.disbursed_date) {
            setStartDate(new Date(props.element.disbursed_date))
        }

    }, [props.element.disbursed_date])

    const onDateChange = (date) => {
        setStartDate(date)
        if(date){
            props.onChange("disbursed_date", DateFormat(date), '', props.element)
        }else{
            props.onChange("disbursed_date",'', '', props.element)
        }        

    }
    
    const { t } = useTranslation('common');
    return (
        <div className={"material animation-effect seller-opt " + (startDate ? 'active-label' : '')}  >
            <div className="datepicker">
                <DatePicker
                    selected={startDate}
                    onChange={(date) => onDateChange(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText={" "}
                    onKeyDown={(e) => {
                        e.preventDefault();
                     }}
                />
                <label data-label={t('FORM.APP_DISBURSED_DATE')  + '*'} className={"form-label " + (startDate ? 'top21' : '')}></label>
                {
                   (props.tempErrors && props.tempErrors.disbursed_date) ? <span className="error show">{props.tempErrors.disbursed_date}</span> : <span>&nbsp;</span>
                }
            </div>
        </div>
    );
}


function Calendra(props) { 
    return (
        <div className={"material animation-effect seller-opt " + (props.selected ? 'active-label' : '')}  >
            <div className="datepicker">
                <DatePicker
                    selected={props.selected ? new Date(props.selected) : null}
                    onChange={(date) => props.onChange(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="--/ --/ --" 
                    dateFormat={props.dateFormat ? props.dateFormat : 'mm/dd/yyyy'}
                    minDate={props.minDate ? new Date(props.minDate) : null}
                    maxDate={props.maxDate ? new Date(props.maxDate) : null}
                />
                 <label data-label={props.label  + '*'} className={"form-label date-label " + (props.selected ? 'top-date' : '')}></label>
                {/* <label data-label="Date/Time" className="form-label"></label>  */}
            </div>
        </div>
    );
}

function IfDate(props) { 

    return (
        <div className={"material animation-effect seller-opt " + (props.if_date ? 'active-label' : '')}  >
            <div className="datepicker">
                <DatePicker
                    selected={props.selected ? new Date(props.selected) : null}
                    onChange={(date) => props.onChange(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="" 
                    dateFormat={props.dateFormat}
                    onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    minDate={props.minDate ? new Date(props.minDate) : null}
                    maxDate={props.maxDate ? new Date(props.maxDate) : null}
                />
                 <label data-label={props.label} className={"form-label date-label " + (props.selected ? 'top-date' : '')}></label>
            </div>
        </div>
    );
}


export { ReceivedDate, DisbursedDate,Calendra,IfDate }
