import React, { useEffect, useState } from 'react'
import Modal from '../../common/Modal'; 
import { CommonActions } from "../../../store/action"; 
import { useTranslation } from "react-i18next";

function AgentPointsHistory(props) { 
    const [historylist, setList] = useState([]); 
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation("common");


    useEffect(() => { 
        getHistoryList();
        // eslint-disable-next-line
    }, [])

    const getHistoryList = () => { 
        setLoading(true);
        let params = {};
        params.user_id = props.id;
        CommonActions.getAgentPointHistoy(params).then(function (response) {
            setLoading(false) 
            if (response && response.status === 200) { 
                setList(response.data);
            }
        }).catch(err=> setLoading(false));
    }
 


    return (
        <div>
            <div className="agent-point-history-popup ">
                <Modal show={props.viewTimeLine} handleClose={() => props.showModalViewTimeLine(false)} >
                    {
                        (historylist.length || props.type) ?
                            <div className="modal-header">
                                <h2>
                                    { 
                                        t('AGENT_POINT_CONFIG.HISTORY')
                                    } 
                                </h2>
                            </div>
                            : null
                    } 

                    <div className="modal-body" >
                        <div className="">
                            <table>
                            <thead>
                                <tr> 
                                    <th>Quarter</th>
                                    <th>Points Redeemed</th>
                                    <th>Destination</th>  
                                </tr>
                            </thead>
                                <tbody>
                                    {
                                        !loading && historylist.length ? historylist.map((item) => {
                                            return (<tr key={item.id}>
                                                <td>
                                                    <span className="date">{item.quarter}</span> 
                                                </td>
                                                <td> 
                                                    <span className="time">{item.points_redeemed || '-'}</span>
                                                </td>
                                                <td>
                                                    <span className="heading wordWrap">{item.destination || '-'}</span> 
                                                </td>

                                            </tr>)
                                        })
                                        : 
                                            <tr><td colSpan={3} className="no-data">{loading ? t('DEALER.LOADING') : t('DEALER.NO_DATA_FOUND')}</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>


                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default AgentPointsHistory