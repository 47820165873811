import React, { Component ,useEffect,useState} from 'react';
import Select from 'react-select'; 
import { connect } from 'react-redux'; 
import CustomValueContainer from '../../common/CustomValueContainer' ;
import MultiSelect from '../../common/MultiSelect'
import { useTranslation } from "react-i18next";



const BasicDetails = (props) => {
    let { element,tempErrors,indexKey,record} = props;
  
    const { product_type, scheme_duration,applicable_to } = props.incentive_config;
    const { t } = useTranslation("common"); 
    //const [refresh, setRefresh] = useState(1);
    const [productTypeOption, setProductTypeOption] = useState({});
    const [schemeDurationOption, setSchemeDurationOption] = useState({});
    const [applicabletoOption, setApplicabletoOption] = useState({});

    useEffect(() => {
        let ProductTypeOption= element.product_type ? { value: element.product_type, label: element.product_type } : {};

        let  SchemeDurationOption=element.scheme_duration ? { value: element.scheme_duration, label: element.scheme_duration } :{};

        let ApplicabletoOption= element.applicable_to ? { value: element.applicable_to, label: element.applicable_to } : {};
        
        setProductTypeOption(ProductTypeOption);
        setSchemeDurationOption(SchemeDurationOption);
        setApplicabletoOption(ApplicabletoOption);
       
    },[props])
 
    const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
        if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {

            return `${value.length} selected`;
        }
        else
            return `${placeholderButtonLabel}`;

    }

    const handleChangeProductType = (ProductTypeOption) => {
        setProductTypeOption(ProductTypeOption);
        props.onChange("product_type", ProductTypeOption, '', props.element)
    };

    const handleChangeSchemeDuration = (SchemeDurationOption) => {
        setSchemeDurationOption(SchemeDurationOption);
        props.onChange("scheme_duration", SchemeDurationOption, '',props.element)
        if(props.element.form_config.SCHEME_DURATION ){
            props.onChange("scheme_duration_value", {...SchemeDurationOption, value: SchemeDurationOption['label']}, '',props.element)
        }
    };

    const handleChangeApplicableto = (applicabletoOption) => {
        setApplicabletoOption(applicabletoOption);
        props.onChange("applicable_to", applicabletoOption.label, '', props.element)
    };
        
    return (
        <React.Fragment>
            <div className='basic-filter-outer'>
                <div className="basic-detail-filter">
                    <fieldset className="single-select">
                        <div className="material">
                            <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            value={element.product_type ? product_type.filter(ob=>ob.value===element.product_type):''}
                            onChange={handleChangeProductType}
                            options={product_type.filter(ob=>ob.incentive_type===1)}
                            isDisabled={element.is_expired}
                            placeholder={t('FORM.PRODUCT_TYPE') + '*'}
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            />
                        </div>
                        { tempErrors && tempErrors.product_type ? <span className="error show">{tempErrors.product_type}</span> : <span>&nbsp;</span>}
                    </fieldset>

                    <fieldset className="single-select">
                        <div className="material">
                            {
                                !element.is_expired 
                                ?
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                    value={element.scheme_duration ? scheme_duration.filter(({ value }) => Number(value) === Number(element.scheme_duration)) : null}
                                    onChange={handleChangeSchemeDuration}
                                    options={scheme_duration}
                                    isDisabled={element.is_expired || false}
                                    placeholder={t('FORM.SCHEME_DURATION') + '*'}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isSearchable={false}
                                />
                                :
                                
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                value={[{value: '', label: element.scheme_duration_value}]}
                                onChange={handleChangeSchemeDuration}
                                options={scheme_duration}
                                isDisabled={element.is_expired}
                                placeholder={t('FORM.SCHEME_DURATION')+ '*'}
                                className="react-select"
                                classNamePrefix="react-select"
                                isSearchable={false}
                            />

                            }
                            
                        </div>
                        {
                            (tempErrors && tempErrors.scheme_duration) ? <span className="error show">{tempErrors.scheme_duration}</span> : <span>&nbsp;</span>
                        }
                    </fieldset>

                    
                        <fieldset className="single-select">
                            <div className="material">
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                value={element.applicable_to?applicable_to.filter((el) =>el.label.toLowerCase() === element.applicable_to.toLowerCase()):''}
                                onChange={handleChangeApplicableto}
                                options={applicable_to}
                                isDisabled={element.is_expired}
                                placeholder={t('FORM.APPLICABLE_TO') + '*'}
                                className="react-select"
                                classNamePrefix="react-select"
                                isSearchable={false}
                                />
                            </div>
                            {
                            (tempErrors && tempErrors.applicable_to) ? <span className="error show">{tempErrors.applicable_to}</span> : <span>&nbsp;</span>
                            }
                        </fieldset>
                </div>
            </div>
        </React.Fragment>
    );       
          
            

}


export default BasicDetails;