import React from 'react';
import { useTranslation } from 'react-i18next'; 
import * as HELPER from './../../../config/helper';
import { useNavigate } from "react-router-dom"; 
import { useSelector } from "react-redux";

const DashboardLeadListTable = (props) => {
    const { t } = useTranslation('common');   
    const history = useNavigate();
    const commonRedux = useSelector((state) => state.common);

    const redirectPayOutDetail = (role_id, month, props)=>{
        let statusName = 'pending';  
        if(commonRedux.incentiveConfig && commonRedux.incentiveConfig.user_status){ 
            let statusData  = commonRedux.incentiveConfig.user_status.filter(ob=>ob.value ===props.navLink); 
            statusName = (statusData.length)? statusData[0]['key']: statusName; 
        }   

        //MONTH FORMATTING FOR URL
        month = new Date(month).toISOString().split('T');
        month = month[0].split('-')

        let urlMonth = month[0]+'-'+month[1];
        let fullUrl = '';
        if(urlMonth){
            let getRoleId = HELPER.GET_USER_ROLE((role_id).toLowerCase())
            fullUrl = `/payout-details?role_type=${(role_id).toLowerCase()}&role_id=${getRoleId}&type=${props.navItem}&status=${statusName}&month=${urlMonth}`
        }
        if(props.searchFilters.reporting_role){
            fullUrl +=  `&reporting_role=${props.searchFilters.reporting_role}`
        }
        if(props.searchFilters.reporting_id){
            fullUrl +=  `&reporting_id=${props.searchFilters.reporting_id}`
        }
        if(props.searchFilters.scheme_id){
            fullUrl +=  `&scheme_id=${props.searchFilters.scheme_id}`
        }
        if(props.searchFilters.upload_csv){
            fullUrl +=  `&upload_csv=${props.searchFilters.upload_csv}`
        }  
        if(props.searchFilters.role_id){
            fullUrl +=  `&role_id=${props.searchFilters.role_id}`
        }
        if(props.searchFilters.business_line){
            fullUrl +=  `&business_line=${props.searchFilters.business_line}`
        }
      history(fullUrl);

    }


    return (
        <React.Fragment>
            <div className='payout-summary-table'>
                <div className='data-table'>
                    <table>
                        <thead>
                            <tr>
                                <th>{t('FORM.USER_ROLE')}</th>
                                <th>{t('FORM.TOTAL_INCENTIVES_EARNED')}</th>
                                <th>{t('FORM.TOTAL_ALLOWANCE_EARNED')}</th>
                                <th>{t('FORM.PRODUCTIVE_USER')}
                                    <span data-tooltip-id="my-tooltip-1" title={""+t('FORM.PU_ICON')} className="tooltio-ic"><i className="ic-info-icon1"></i></span>
                                </th>
                                <th>{t('FORM.ELIGIBLE_USER')}</th>
                                <th>{t('FORM.CONVERTION')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.apiResponse && props.apiResponse.items && props.apiResponse.items.length
                                ?
                                    props.apiResponse.items.map((item,key) => {
                                   
                                        return (<tr key={key}>
                                            <td>{item.role}</td>
                                            <td>{HELPER.addCurrencyFormatting(item.total_incentive)}</td>
                                            <td>{HELPER.addCurrencyFormatting(item.total_allowance)}</td>
                                            <td>{item.active_users}</td>
                                            <td>{item.eligible_users > 0 ? 
                                              <span className="span-link" onClick={()=>redirectPayOutDetail(item.role, props.month, props)} >{item.eligible_users}</span>
                                            : 0 
                                            }</td>
                                            <td>{item.convertion + ' %'}</td>
                                        </tr>)
                                    })
                                :
                                    <tr><td className='t-center' colSpan={6}>No Data Found</td></tr>
                            }

                        </tbody> 
                    </table>
                </div>
                {/* <div className="pagination-outer">
                    <Pagination paginateData={{ page, runPaginationFunc, loading: loading, 'tableData': [], totalLengthWithoutPagination, paginationData, filterData }} action="leadList" sendRequestDataToParent={handleRequestData} />
                </div> */}
            </div>
        </React.Fragment>
    )
}

export default DashboardLeadListTable;