import React, { useState, useEffect } from "react";
import LeadListFilter from "./common/LeadListTopFilter";
import { useTranslation } from "react-i18next";
import PageHeader from "./common/PageHeader";
import { Tab, Nav } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { CommonActions } from "../../store/action";
import PaidAmount from "./PaidAmount";
import DashboardLeadListTable from "./common/DashboardLeadListTable";
import FullPageLoader from '../common/FullPageLoader';
import * as HELPER from './../../config/helper';

const Dashboard = (props) => {
    const { t } = useTranslation("common");
    const [loading, setLoading] = useState(false);

    const [searchFilters, setSearchFilters] = useState({});
    const [payoutTitle, setPayoutTitle] = useState();
    const [selectedBusinessType, setSelectedBusinessType] = useState("All")

    const getTitle = (Monthdate) => {
        const month = new Date(Monthdate).toLocaleString("default", {
            month: "short",
        });
        let payouTittle=t("FORM.PAYOUT_SUMMARY_FOR") + " " + month + " " +new Date(Monthdate).getFullYear();
        setPayoutTitle(payouTittle);
    };

    const dispatch = useDispatch();
    const commonRedux = useSelector((state) => state.common);

    useEffect(() => {

        //CREATE DEFAULT DATE FOR MONTH FILTER
        let makeDate = new Date();
        const currentDate = String(makeDate.getDate()).padStart(2);

        if (currentDate <= 20) {
            makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
        }
        getDashboardList({status:'0', month:makeDate})
      // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    let apiResponse =
        commonRedux &&
            commonRedux.adminDashboard &&
            Object.keys(commonRedux.adminDashboard).length
            ? commonRedux.adminDashboard
            : {};

    let checkForPdfUpload = commonRedux && commonRedux.pdfUploadDtls && commonRedux.pdfUploadDtls.data ? true : false;

    const getDashboardList = (param, searchFiltersObj=searchFilters) => {
        //MAP STATUS FROM CONFIG FILTER API
    
        if (param.status) {
            let getUserStatus = ( commonRedux.incentiveConfig && commonRedux.incentiveConfig.user_status ) || []
            let selectedStatus = getUserStatus.filter(st=> (''+st.value === ''+param.status || ''+st.key === ''+param.status));
            param.status = selectedStatus[0] ? selectedStatus[0]['value'] : 0;
        }
        let paramOb = {};
        //IF ALL SELECTED REMOVE FROM REQUEST PARAMS
        if(param.type === 'All') {
            delete searchFiltersObj['type']
            paramOb = searchFiltersObj
        }else{
            paramOb = { ...searchFiltersObj, ...param };
        }  

        //DEL RESET REFERENCE
        if(paramOb.reset){
            delete paramOb['reset'];
        }
        if(param.month){
            getTitle(param.month);
        }
        setSearchFilters(paramOb);
        setLoading(true)
        dispatch(CommonActions.adminDashboard(paramOb)).then(res=> setLoading(false)).catch(err=> setLoading(false));
        dispatch(CommonActions.getPdfUploadDtls(paramOb)); 
    };


    /**
     * BUSINESS TYPE TAB CLICK HANDLER
     * @param {*} params 
     */
    const businessTypeClickHandler = (params) => {
        setSelectedBusinessType(params.type);
        getDashboardList(params)
    }

    

    /**
     * RESET FILTER
     */
    const resetFilters = (params={}) => {
        setSearchFilters(params)
        //RESET BUSINESSTYPE TAB
        setSelectedBusinessType("All")
        
        getDashboardList(params, {})
    }

    useEffect(()=>{
        
    },[selectedBusinessType])

    let payoutType = (selectedBusinessType) ? selectedBusinessType.toLowerCase() :''; 
    let title=payoutTitle; 
    return (
        <React.Fragment>
          <FullPageLoader show={loading} />

            <div className="dashboard-outer">
                <div className="container-fluid">
                    <div className="top-heading">
                        <h1>{t("COMMON.INCENTIVE_DASHBOARD")}</h1>
                    </div>
                    <div className="loan-filter-bg dashboard-filter-top">
                        {/* FILTER RESET - SEARCH BUTTON */}
                        <LeadListFilter
                            action="dashboard"
                            resetFilters={resetFilters}
                            searchFilters={searchFilters}
                            setSearchFilters={setSearchFilters}
                            getDashboardList={getDashboardList}
                            
                        />
                    </div>

                    <PageHeader action="dashboard" title={title}  pendingRecords={HELPER.numberFormat((apiResponse.paid && apiResponse.paid.pending) || 0)} checkForPdfUpload = {checkForPdfUpload} searchFilters={searchFilters}/>

                    <div className="payout-summary-tabs">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="All">
                            {/* MAIN HEADING */}
                            <Nav variant="pills" className="flex-column tab-line">
                                <div className="tab-list">
                                    {/* {Object.keys(apiResponse).length > 0 &&
                                        apiResponse.total_counts.map((item) => {
                                            return (
                                                <Nav.Item
                                                    key={item.key}
                                                    onClick={() => businessTypeClickHandler({ type: item.key })}
                                                    atttr={selectedBusinessType}
                                                >
                                                    <Nav.Link eventKey={item.key}
                                                    active={selectedBusinessType === item.key ? true : false }
                                                    >
                                                        {item.key} ({item.value})
                                                    </Nav.Link>
                                                </Nav.Item>
                                            );
                                        })} */}
                                </div>
                                {Object.keys(apiResponse).length > 0 &&
                                    Object.keys(apiResponse.paid).length && (
                                        <PaidAmount amount={apiResponse.paid} action="dashboard" />
                                    )}
                            </Nav>
                            {Object.keys(apiResponse).length > 0 ?
                                apiResponse.total_counts.map((item) => {
                                    return (
                                        <Tab.Content key={item.key}>
                                            <Tab.Pane eventKey={item.key}>
                                                <div className="approve-status-tabs">
                                                    <Tab.Container
                                                        id="left-tabs-example"
                                                        defaultActiveKey="pending"
                                                    >
                                                        <Nav variant="pills" className="flex-column tab-bg">
                                                            <div className="tab-list">
                                                                {apiResponse.statusList.length &&
                                                                    apiResponse.statusList.map((ob) => {
                                                                        return (
                                                                           // SUB HEADING
                                                                            <Nav.Item key={ob.key}>
                                                                                <Nav.Link
                                                                                    active={(typeof searchFilters.status !== "undefined" && +searchFilters.status === +ob.status_id ) ? true : (typeof searchFilters.status === "undefined" && +ob.status_id === 0 ? true : false)  }
                                                                                    eventKey={ob.key}
                                                                                    onClick={() => getDashboardList({ status: ob.key.replace(' ', '_') })}
                                                                                >
                                                                                    {t("DASHBOARD." + ob.key)}({ob.value})
                                                                                </Nav.Link>
                                                                            </Nav.Item>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </Nav>
                                                        {/* DASHBOARD TABLE */}
                                                        <DashboardLeadListTable apiResponse={apiResponse}  navItem ={payoutType} navLink = {searchFilters.status} month = {searchFilters.month} searchFilters = {searchFilters}/>
                                                    </Tab.Container>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    );
                                })
                                : (loading)? null: <div className="no-data">{t("COMMON.NO_DATA")}</div>
                            }
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;
