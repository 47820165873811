import Modal from "../../common/Modal";
import { useTranslation } from 'react-i18next';
 import { useState } from "react";
import TargetHistoryTable from "./TargetHistoryTable";
import TargetFileUpload from "./TargetFileUpload";
 

function AddTargetData(props) {
    let {sampleFile, bulkUploadPopUp} = props;
    const { t } = useTranslation('common');  
    const [fileUploadType, setBulkUploadType] = useState(4); 
 

   
    return ( 
        <> 
            <div className={"model-popup-outer bulk-upload-history-popup"}>
                <Modal show={bulkUploadPopUp} handleClose={()=>props.handleClose()} >
            
                    <div className="modal-body">
                    <div className='bulk-upload-history-content'>
                                <div className='bulk-upload-sec'>
                                    <h3>Target Upload</h3>
                                    <TargetFileUpload month={props.month} fileUploadType={fileUploadType} />

                                </div>
                                <TargetHistoryTable fileUploadType={fileUploadType} />
                            </div> 
                        
                    </div>
                    <div className="modal-footer">
                        <div className="download-txt">
                            Download input format < a href={sampleFile}>
                                Click Here
                            </a>
                        </div>
                    </div>
                </Modal>
            </div> 
        </> 
  );
}

export default AddTargetData;