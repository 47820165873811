import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ConfirmationModal from "../../common/ConfirmationModal";
import { useTranslation } from 'react-i18next';
import BulkUploader from "./BulkUploader";
import { CommonActions } from "../../../store/action";
import { toast } from "react-toastify";
import DataExport from './DataExport';
import PdfUploader from './PdfUploader';
import {INCENTIVE_FILE_TYPES, INCENTIVE_FILES_OBJ} from '../../../config/constant';

const PageHeader = (props) => {
   
    const commonRedux = useSelector((state) => state.common)
    const [confimationPopUp, setConfirmationPopup] = useState({ status: false, msg: '' });
    const [exportedPopUp, setExportedPopUp] = useState(false);
    const [teamMember, setTeamMember] = useState('');
    const [dummyBulkuploadUrl, setDummyBulkuploadUrl] = useState({});
    
    const { t } = useTranslation('common');
    const dispatch = useDispatch();

    useEffect(() => {

        setTeamMember('');
    }, [props.refresh])



    useEffect(() => {
        if(commonRedux.incentiveConfig?.sampleFiles && commonRedux.incentiveConfig.sampleFiles.length){
            const filteredArr = commonRedux.incentiveConfig.sampleFiles?.filter(item => INCENTIVE_FILE_TYPES.includes(item.type)); 
            const result = {};
            filteredArr.forEach(obj => {
                result[INCENTIVE_FILES_OBJ[obj.type]] = obj.url;
            });
            setDummyBulkuploadUrl(result)
        }
        // eslint-disable-next-line
    }, [commonRedux && commonRedux.incentiveConfig])

  

    const showModalConfimationPopUp = (params) => {
        let { status } = params
        setConfirmationPopup(params)
        // setApproveRejectMsg(msg)
        document.body.classList.remove("overflow-hidden");
        if (status) {
            document.body.classList.add("overflow-hidden");
        }


    }


    const showModalExportedPopUp = () => {
        setExportedPopUp(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideModalExportedPopUp = () => {
        setExportedPopUp(false)
        document.body.classList.remove("overflow-hidden");
    }
    const setSearchkey=(val)=>{
         setTeamMember(val);
         props.setFilter({ search_key: val })
    }

    const apiResponse = (val) => {
        if (val.key === 'Enter') {
            props.getDashboardList({ ...props.searchFilters, search_key: teamMember, page_no: 1 })
        }
    }

    const confirmationModalResponse = (params) => {
        let value = (confimationPopUp.approve) ? 1 : 2
        
        let incentive_month = commonRedux.payoutReqParams.month || props.routeParams.get('month');

        let selectedRole = props.searchFilters && props.searchFilters.role_type ? props.searchFilters.role_type : props.routeParams.get('roleName');
        let selectedScheme = undefined //props.searchFilters && props.searchFilters.scheme_id ? props.searchFilters.scheme_id : undefined;

        //CALL SERVICE TO UPDATE STATUS
        if (params.response) {
            props.setLoading(true)
            let updateStatusParams = { status: value, role: selectedRole, month: incentive_month, selectedScheme };

            let updatedUsers = commonRedux.getMultiselectedPayouts;
 
            //CHECK UNSELECTED IDs & FILTER FROM ALL SELECTED PENDING RECORDS
            if(commonRedux && commonRedux.getMultiselectedExcludedPayouts && commonRedux.getMultiselectedExcludedPayouts.length){
                    updatedUsers = updatedUsers.filter(users=> !commonRedux.getMultiselectedExcludedPayouts.includes(users) )
            }
            updateStatusParams['parent_id'] = JSON.stringify(updatedUsers);
            updateStatusParams['is_all'] =  commonRedux.checkedallStatus;   
            if(commonRedux.page_status){
                updateStatusParams['status_type'] =  commonRedux.page_status; 
            }
            if(updatedUsers.length){
                dispatch(CommonActions.updateStatus(updateStatusParams)).then((res) => {
                    props.setLoading(false) 
                    if (res.status === 200) {
                        dispatch(CommonActions.updateApproveButtonStatus(false)); 
                        dispatch(CommonActions.getMultiSelectedLoans([]));
                        dispatch(CommonActions.setExcludeMultiSelectedSfa([]));
                        toast.success(res.message);
                    } else {
                        toast.error(res.message);
                    }
                    props.getDashboardList(props.searchFilters);
                });
            }else{
                toast.error("Please select at least one record");
            }
            
        }

        showModalConfimationPopUp(params)
    }
    const userInfo = useSelector((state) => state.auth && state.auth.authUser && state.auth.authUser.user_data);  
    let btn_expt_disabled=(commonRedux && commonRedux.payoutDetails && commonRedux.payoutDetails.data && commonRedux.payoutDetails.data.length)?false:true;

    return <>

        <div className="sub-heading p-lg-t p-lg-b">
            <h2>{props.title} </h2>
            <div className="report-heading-right-panel">
                {
                    props.action === 'dashboard' &&
                    <div className="pending-record-txt">
                        <span>{props.pendingRecords} {t('FORM.PENDING_RECORDS')} </span>
                        {t('FORM.FOR_PAST_MONTHS')}
                    </div>
                }
                {
                    commonRedux.approveButton && props.showActionButtons
                        ?
                        <>
                            <button className="btn-line btn-export m-sm-r" onClick={() => showModalConfimationPopUp({ status: true, approve: true, msg: "Are you sure you want to approve this?" })}> <i className="ic-check"></i>{t('FORM.APPROVE')}</button>
                            <button className="btn-line btn-export m-sm-r" onClick={() => showModalConfimationPopUp({ status: true, reject: true, msg: "Are you sure you want to reject this?" })}> <i className="ic-clearclose"></i>{t('FORM.REJECT')}</button>
                        </>
                        :
                        ""
                }

                <div className='search-bx m-sm-r'>
                    {props.action !== 'dashboard'
                        ?
                        <div className='material'>
                            <input type="text" placeholder='Search by SFA ID, Name, Phone or Email' className='form-input' maxLength={200} value={teamMember}
                                onKeyPress={(e) => apiResponse(e)}
                                onChange={(e) => setSearchkey(e.target.value)}
                            />
                            <i className='ic-search'></i>
                        </div>
                        :
                        ''
                    }
                </div>
                {
                props.action === "dashboard" ? (userInfo && userInfo.config) ? <PdfUploader checkForPdfUpload={props.checkForPdfUpload} month = {props.searchFilters ? props.searchFilters.month : null}/> : null : null 

                }
                {
                    props.action === "dashboard"
                        ?
                        (userInfo && userInfo.config && userInfo.config.DASHBOARD &&  userInfo.config.DASHBOARD.hasOwnProperty('BULK_UPLOAD')) ? <BulkUploader BulkuploadUrl={dummyBulkuploadUrl} month = {props.searchFilters ? props.searchFilters.month : null} /> : null
                        :
                        <button className="btn-line btn-export m-sm-l" disabled={btn_expt_disabled} onClick={showModalExportedPopUp}> <i className="ic-export"></i>{t('FORM.EXPORT_DATA')} </ button>

                }
                

            </div>
        </div>

        <ConfirmationModal confirmationData={{ show: confimationPopUp.status, confirmationText: confimationPopUp.msg }} confirmationModalResponse={confirmationModalResponse} />
        
        {
            exportedPopUp && <DataExport show={exportedPopUp}  handleClose={hideModalExportedPopUp} />
        } 
    </>
        ;
}

export default PageHeader