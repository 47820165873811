import React from "react";
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UserActions,CommonActions } from "../../../store/action";
import { INCENTIVE_IMAGE_FOLDER } from "../../../config/constant"
import secureStorage from '../../../config/encrypt';

class ImageUploader extends React.Component {
    constructor(props) {
        super(props);
        const authUserInfo = secureStorage.getItem('authUserInfo') || {}; 
        const userId = authUserInfo?.user_data?.id || 1;
        this.handleAddImage = this.handleAddImage.bind(this);
        this.state = {
            file: null,
            loading: false,
            dragOver: false,
            errorNoficication: null,
            fileUploadType:props.fileUploadType,
            fileType : props.fileType,
            upload_type_id: props.upload_type_id,
            user_id : userId
        };
    }
    
    static getDerivedStateFromProps = (nextProps, prevState) => {
        if(nextProps?.fileUploadType!== prevState?.fileUploadType){
            return ({ fileUploadType: nextProps.fileUploadType })
        } 
        return null;
    }
    /**
       Drag and Drop Event Handlers
    **/
    handleDragEnter(e) {
        e.preventDefault();
    }
    handleDragOver(e) {

        e.preventDefault();
        if (!this.state.dragOver) {
            this.setState({
                dragOver: true
            });
        }
    }
    handleDragLeave(e) {
        e.preventDefault();
        this.setState({
            dragOver: false
        });
    }
    handleDrop(e) {
        e.preventDefault();
        let file = e.dataTransfer.files[0];

        // Validate file is of type Image
        let fileType = file.type.split("/")[0];
        if (fileType !== "image") {
            this.setState({
                file: null,
                errorNotification: "Not an image File",
                dragOver: false
            });
            return setTimeout(() => {
                this.setState({
                    errorNotification: null
                });
            }, 3000);
        }
        // this.refs.image.files = e.dataTransfer.files;
        document.getElementById('upload-image-input').fileList = e.dataTransfer.files[0];
        this.setState({
            file,
            dragOver: false
        });
    }

    /**
    * On Submit filter
    */
     submitUpload = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        let fileName = (this.state.file) ? this.state.file.name : '';
        const form = event.target;
        const data = new FormData(form);
        data.append("filename", fileName);
        if(this.state.upload_type_id){
            data.append('upload_type', this.state.upload_type_id || 1)
            data.append("visit_id", 0);
            if(this.state.fileUploadType == 2){
                data.append("user_id", this.state.user_id);
            }
        }else{
            data.append('upload_type', INCENTIVE_IMAGE_FOLDER)
            data.append("user_id", this.state.user_id || 1);
        } 
        data.append("images", this.state.file);
        data.append("type", this.state.fileUploadType??1);
        if(this.state.fileType && this.state.fileType === 'pdf'){
            data.append("month", this.props.month);
        }
        const formdata = data;
        let headers = {
            'Content-Type': `multipart/form-data;boundary=${formdata._boundary}`,
        }
        let checkFileType = (this.state.fileType && this.state.fileType === 'pdf') ? (this.state.file && fileName && (/\.(pdf)$/.test(fileName))) : (this.state.file && fileName && (/\.(csv)$/.test(fileName)));
        if (checkFileType) {
            try{
                let searchResult;
                if(this.state.fileType && this.state.fileType === 'pdf'){
                    searchResult = await this.props.actions.submitPdfUpload(formdata, headers);
                } else {
                    searchResult = await this.props.actions.submitBulkUploadIncentive(formdata, headers);
                }
               
                this.setState({ loading: false }); 
           
                if (searchResult.status === 200) {
                    if (searchResult.data && searchResult.data.encodingError) {
                        toast.error(searchResult.message);
                    } else {
                        if(this.state.fileType && this.state.fileType === 'pdf'){
                            this.props.actions.getPdfUploadDtls({type : this.state.fileUploadType, month: this.props.month});
                        } else {
                            this.props.actions.getCsvUploadHistory();
                        }
                        toast.success(searchResult.message);
                    }

                    this.setState({file: ''});
                    if (this.fileInput && this.fileInput.value) {
                        this.fileInput.value = "";
                    }
                }else {
                    toast.error(searchResult.message);
                    this.setState({file: ''});
                    if (this.fileInput && this.fileInput.value) {
                        this.fileInput.value = "";
                    } 
                }
            }catch(err){
                toast.error(err.message);
                this.setState({loading: false,file: ''});
                if (this.fileInput && this.fileInput.value) {
                    this.fileInput.value = "";
                } 
            }
           
        }else {
            toast.error("Please select valid File");
            this.setState({ loading: false,file: ''});
            if (this.fileInput && this.fileInput.value) {
                this.fileInput.value = "";
            }
        }
        

    }
    /**
       Handle Manually (File Input) Added Files
    **/
   
    handleAddImage = async (e) => {    
        e.preventDefault();
        let file = e.target.files[0];
        if(file){
            if(this.state.fileType && this.state.fileType === 'pdf') {
                if(!['application/pdf','application/wps-office.pdf'].includes(file.type)){
                    this.setState({ loading: false });
                    toast.error("Please select valid File");
                } else {
                    this.setState({
                        file: file
                    });
                    this.props.uploadStatusHandler({pdfUploadStatus: true, pdfUploadMsg: ""})
                }
            }  else { 
                if(!["application/wps-office.xls", "application/wps-office.xlsx", "text/csv"].includes(file.type)) {
                this.setState({ loading: false });
                toast.error("Please select valid File");
                } else {
                    this.setState({
                        file: file
                    });
                    this.props.uploadStatusHandler({bulkUploadStatus: true, bulkUploadMsg: ""})
                }
            } 
        }   
    }

    /**
     * OPEN FILE UPLOAD BOX
     */
     openFileUpload = () => {
        document.getElementsByClassName('incentiveCsv')[0].click()
    }

    /**
       Handle Upload after Upload Button Clicked
    **/
    handleUploadImage(e) {
        e.preventDefault();
        if (this.e.image.files[0]) {
            /**
               Handle image Upload
            **/

        }
    }
    handleCancelUpload(e) {
        this.setState({
            file: undefined
        });
        document.getElementsByClassName('incentiveCsv')[0].value = null
    }


    render() {
        const { loading, file } = this.state;

        // Match drag over css to hover css
        let dragOverClass = this.state.dragOver
            ? `display-box drag-over`
            : `display-box`;

        // If file is set, change upload box text to file name
        let uploadText = this.state.file
            ? <div className="upload-file-name">
            
                <div className="upload-file-img">
                    <span className="suceess-icn">
                        <i className="ic-check"></i>
                    </span>
                    <h4>
                        {this.state.file.name}
                    </h4>
                    <i className="ic-clearclose" onClick={(event) => this.handleCancelUpload(event)}></i>
                </div>

                <button
                    className="upload-button btn btn-primary"
                    type="button" onClick={(event) => this.openFileUpload(event)}
                >
                    {this.props.t('MESSAGE.BROWSE_FILE')}
                </button>

              
            </div>
            : <div className="upload-txt">
                <h4> {this.props.t('MESSAGE.DROP_FILE')}</h4>
                <span> {(this.state.fileType && this.state.fileType === 'pdf') ? this.props.t('MESSAGE.PDF_ACCEPTED') : this.props.t('MESSAGE.XLS_ACCEPTED')}</span>
                <div className="or-txt">
                    or
                </div>
               
                <button
                    className="btn-primary file-btn" type="button" onClick={(event) => this.handleAddImage(event)}
                >
                    Browse File
                </button>        

            </div>;

       

        return (
            <div style={{ textAlign: "center" }} >
             <form method="post" onSubmit={this.submitUpload} encType="multipart/form-data">

            
                <div className="image-uploader-wrapper">
                    <div className={dragOverClass}>
                        <div className="icon-text-box">
                            <div className="upload-icon">
                                <i className="ic-file-icon" aria-hidden="true" />
                            </div>

                            <div className="upload-text">
                                {uploadText}
                            </div>
                        </div>
                        <div>
                            <input
                                type="file"
                                ref={ref => this.fileInput = ref} 
                                id="upload-image-input"
                                className="upload-image-input incentiveCsv"
                                accept= {(this.state.fileType && this.state.fileType === 'pdf') ? "application/pdf','application/wps-office.pdf" : ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}  
                                onChange={(event) => this.handleAddImage(event)}
                                style={file ? { width: 0, height: 0 } : { width: '100%', height: '100%' }}
                            />
                        </div>
                    </div>
                </div>

            <div className="modal-footer footer-btn">
                {
                    !loading
                        ?
                        <button className="btn-primary" >Upload</button>
                        :
                        <button className="btn-primary" type='button'>{this.props.t('COMMON.PLEASE_WAIT')}</button>

                }  
            </div>
            </form>    
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions :
            {submitBulkUploadIncentive: bindActionCreators(
                UserActions.submitBulkUploadIncentive,
                dispatch
            ),
            getCsvUploadHistory: bindActionCreators(
                CommonActions.getCsvUploadHistory,
                dispatch
            ),
            submitPdfUpload: bindActionCreators(
                UserActions.submitPdfUpload,
                dispatch
            ),
            getPdfUploadDtls : bindActionCreators(
                CommonActions.getPdfUploadDtls,
                dispatch
            )
        }
    }
}



export default withTranslation('common')(connect(null,mapDispatchToProps)(ImageUploader));






