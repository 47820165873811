import Modal from "../../common/Modal";
import FileUpload from "../../dashboard/common/FileUpload";
import { useState } from "react";
import { INCENTIVE_IMAGE_FOLDER } from "../../../config/constant"

function IfPdfUploader(props) {

    const [fileUplodValue, setPdfUpload] = useState({
        pdfUpload: false,
        pdfUploadStatus: false,
        pdfUploadMsg: false
    })
    
   
    /**
     * BULK UPLOAD STATUS HANDLER
     * @param {*} params 
     */
    const uploadStatusHandler = (params) => {
        setPdfUpload({
            ...fileUplodValue, ...params
        })
    }
   
    
    return (
        <div className="model-popup-outer">
        <Modal show={props.pdfUploadPopUp} handleClose={()=>props.hideModalPdfUploadPopUp()}>
            <div className="modal-header">
            <h3>{"Upload Incentive T&C"}</h3>
            </div>
            <div className="modal-body">
                <div className='pdf-upload-history-content'>
                    <div className='pdf-upload-sec'>
                       
                        <FileUpload uploadStatusHandler={uploadStatusHandler} fileUplodValue={fileUplodValue} fileUploadType={2} fileType = {"pdf"} month = {props.month} upload_type_id={INCENTIVE_IMAGE_FOLDER}/>

                    </div>
                </div>
            </div>
        </Modal>
        </div>
        
  );
}

export default IfPdfUploader;