import React, { useState } from 'react'
import Modal from '../../common/Modal'
import { useSelector, useDispatch } from "react-redux";
import { CommonActions,IfActions } from "../../../store/action";
import { useTranslation } from 'react-i18next';
import RoleList from '../../incentive/RoleList';


function AddIfIncentive(props) {

    const { t } = useTranslation('common');
    const [schemId, setSchemaId] = useState('');
    const [showError,setError] =  useState(false);
    const dispatch = useDispatch();
    const [schemaList, setSchemaList] = useState([]);
    const [loading, setLoading] = useState(false); 
    const roleSchemeList = useSelector((state) => {
        return (state.if && state.if.roleSchemeList) ? state.if.roleSchemeList : []
    })

    const [showSchemeListPopup, setShowSchemeListPopup] = useState(false);

    const changeHandler = (e) => {
        setSchemaId(e.target.value);
    }

    const submitIncentive = () => {
        let schemaName = (schemId) ? schemaList.filter(ob => Number(ob.id) === Number(schemId))[0].scheme : ''
        let data = {
            name: schemaName,
            id: schemId
        } 
     
        if(schemId!==''){
            
            setError(false);
            setSchemaId('');
            setShowSchemeListPopup(false);
            props.setIncentiveType(data)
            props.handleClose();
        }else{
            setError(true);
        }
        
    }
    /**
     *  RoleWise Scheme List
     * @param {*} roleId 
     */ 

    const showSchemePopup = async (roleId) => {
        if(roleId){
            await props.handleChangeSchemeRole({"value":roleId,"label":roleId});
            setShowSchemeListPopup(roleId);    
            if(roleSchemeList[roleId]){
              setSchemaList(roleSchemeList[roleId]);
            }
            else{
                setLoading(true);
                dispatch(IfActions.ifSchemeList({'role_id':roleId })).then((res)=>{
                    setLoading(false)
                    setSchemaList(res); 
                    
                });           
            }
        }
        else {
            setSchemaId(false);       
            setShowSchemeListPopup(false);
            setError(false);
        }
    }

    return (
        <>
            <RoleList handleClose={props.handleClose} showSchemePopup={showSchemePopup}  isOpen={props.isOpen} type={"if_status"} />

            <div className="add-incentive-popup model-popup-outer"
            >
                <Modal
                    show={showSchemeListPopup}
                    handleClose={() => showSchemePopup(0)}
                >
                    <div className="modal-header">
                        <h2>{t('COMMON.ADD_INCENTIVE')} </h2>
                    </div>
                    <div className="modal-body radio-btn"
                    >
                        <div className="add-incentive-option radio-type-txt"
                        >
                            {!loading && schemaList.length > 0 ? (
                                schemaList.map((item) => (
                                    <div
                                        key={item.id}
                                        className="custom-control custom-checkbox"
                                    >

                                        <input
                                            id="Incentive"
                                            type="radio"
                                            className="custom-control-input "
                                            name="incentive_scheme_selected"
                                            value={item.id}
                                            onClick={changeHandler}
                                            onChange={()=>{}}
                                            style={{ cursor: 'pointer' }}
                                            checked={Number(schemId) === Number(item.id) ? true : false}
                                        />
                                        <label className="custom-control-label">
                                            <span>{item.scheme}</span>
                                        </label>
                                    </div>

                                ))
                            ) :
                                <div className="custom-control custom-checkbox">
                                    <label className="content">
                                       <span><center> {loading ? 'loading ...': 'No Scheme Found'}</center></span>
                                    </label>
                                </div>
                            }
                        </div>
                        {
                            showError && 
                            <div> 
                                <span className="error show"> {t('VALIDATION.SELECT_SCHEME')}</span> 
                             </div>
                        }
                       
                        {schemaList.length > 0 ?
                            <button onClick={submitIncentive} className="btn-primary m-md-t"  >
                                {t('COMMON.ADD_INCENTIVE_SCHEME')}
                            </button> :
                            null
                        }
                    </div>
                </Modal>
            </div>
        </>
    )
}


export default AddIfIncentive; 
