import React, { useState, useEffect } from "react";
import Select from "react-select"; 
import MultiSelect from '../../common/MultiSelect'
import { MonthYear } from "./IfDatePicker"; 
import { useSelector, useDispatch } from "react-redux";
import { CommonActions, IfActions } from "../../../store/action";
import { toast } from "react-toastify";
import CustomValueContainer from "../../common/CustomValueContainer";
import * as HELPER from './../../../config/helper';
import { ROLE_LABEL_ID } from "../../../config/constant"
import { useParams, useLocation, useNavigate } from 'react-router-dom';

const LeadListFilter = (props) => {
    const [incentiveNameOption, setIncentiveNameOption] = useState([]);
    const [reportManagerOption, setReportManagerOption] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState(props.searchFilters || {});
    const [incentiveConfigData, set_incentive_config_data] = useState([]);
    const [uploadScheme, setUploadScheme] = useState([])
    const { roleName } = useParams();
    const [reportingRoleList, setReportingRoleList] = useState([]);

    const dispatch = useDispatch();
    const commonRedux = useSelector((state) => state.common);
    let roleOption = commonRedux.getRoleList ? commonRedux.getRoleList?.filter(role => +role.if_status === 1 || +role.sfa_role_id === 52) : [];
    const [resetStateFilter, setResetStateFilter] = useState(false); 
 
    let makeDate =  props.searchFilters.incentive_month ? new Date(props.searchFilters.incentive_month) : new Date();
 
    useEffect(() => {
        if (commonRedux && commonRedux.incentiveConfig && commonRedux.incentiveConfig.reporting_to_role) { 
            let reportingRolesForBm = commonRedux.incentiveConfig.reporting_to_role?.filter(data => data.id != 1 && data.id != 3)
            setReportingRoleList(reportingRolesForBm);
        }
 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonRedux]);

 
    useEffect(() => { 
        dispatch(CommonActions.getRoleList());
        // SET MORE FILTER VALUES
        dispatch(CommonActions.getIncentiveConfig()).then(function (response) {
            if (response && response.status === 200) {
                let configResponse = response.data;

                configResponse.agent_level = configResponse.agent_level.map((ob) => {
                    return {
                        ...ob,
                        value: ob.id,
                        label: ob.agent_name,
                    };
                });

                set_incentive_config_data(configResponse);


            } else {
                toast.error(response && response.message);
            }
        });
        
        setSelectedFilters((val) => { return { ...val, incentive_month: makeDate } });
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUploadedDocs = (date) => {
        let parseMonth = new Date(date).toISOString().split('T');
        parseMonth = parseMonth[0].split("-");

        getUploadedCsvFiles({ month_year: parseMonth[0] + '-' + parseMonth[1] })
    }

    const changeHandler = (value, name) => {
        let selectedFiltersObj = selectedFilters; 
        let searchFiltersObj = props.searchFilters; 
        if (name === "role_id") { 
            if (value) { 
                dispatch(IfActions.ifSchemeList({'role_id': value.id })).then((res) => {
                    setIncentiveNameOption(res);
                }); 
                value = value.sfa_role_id;  //RESET SCHEME IDs/Level/Tagging ON ROLE CHANGE
                delete selectedFiltersObj['scheme_type_id'];  
                selectedFiltersObj["role_type"] = value?.name?.toLowerCase();
                setSelectedFilters(selectedFiltersObj);
                props.setSearchFilters(searchFiltersObj);

            }
            else { 
                value = undefined;
                setIncentiveNameOption([]);
                let newRecord = selectedFilters;     // Delete record from payload when we click cross icon
                delete newRecord.scheme_type_id;
                delete newRecord.role_type;
                delete newRecord.role_id;

                delete searchFiltersObj.scheme_type_id; 
                delete searchFiltersObj.role_id; 
                props.setSearchFilters(searchFiltersObj);
                setSelectedFilters(newRecord);
            }
        }
        if (name === "reporting_role") {
         
            if (value) {
                value.name = value.name.toLowerCase()  // For Reset Reportee Manger value before selecting Reporting to Role
                setReportManagerOption([]);
                let newRecord = selectedFilters; 
                delete newRecord.reporting_id;
                delete newRecord.reporting_role;

                delete searchFiltersObj.reporting_id;
                delete searchFiltersObj.reporting_role;
                props.setSearchFilters(searchFiltersObj);
                setSelectedFilters(newRecord); 
                getReportingManagerName(value.name, selectedFilters.incentive_month)
            
            } else { 
                value = undefined;
                setReportManagerOption([]);
                let newRecord = selectedFilters; 
                delete newRecord.reporting_id;
                delete newRecord.reporting_role;

                delete searchFiltersObj.reporting_id;
                delete searchFiltersObj.reporting_role;
                props.setSearchFilters(searchFiltersObj);
                setSelectedFilters(newRecord);
            }

        }
        if (["reporting_role", "upload_csv"].includes(name)) {
            if (value) {
                value = value.name;
            } else {
                value = undefined;
            }
        }

        else if (["scheme_type_id"].includes(name)) {
            value = value.map((v) => v.value);
        } else if (["reporting_id"].includes(name)) {
            if (value) {
                value = value.value;
            } else {
                value = undefined;
            }
        } 

        setSelectedFilters((prev) => {  
            return { ...prev, [name]: value };
        });


    };

    /**
     * UPDATE FILTERS
     * @param {*} params 
     */
    const updateFilters = (params) => {
        let parseMonth = makeDate;
        //IF NO FILTER SELECTED SET DEFAULT DATE
        if (!Object.keys(params).length) {
            params = { incentive_month: parseMonth }
        }

        setSelectedFilters(params);
    };


    /**
     * RESET FILTER
     */

    const resetFilters = () => {  
        setIncentiveNameOption([]);
        let resetparams = { incentive_month: new Date(), status: 1, reset: true };
        props.resetFilters(resetparams);
        setSelectedFilters({ incentive_month: new Date(), status: 1 });

        setResetStateFilter(true);

            setTimeout(() => {
                setResetStateFilter(false);

            }, 1000)
    }

    /**
     * GET UPLOADED CSV API
     * @param {*} params 
     */
    const getUploadedCsvFiles = async (params) => {
        dispatch(CommonActions.getCsvUploadHistory(params)).then(res => {
            if (res.data && res.data.data.length > 0) {
                let record = res.data.data.filter(Data=>Data.upload_type===5).map((ob) => {
                    return {
                        label: ob.csv_filename,
                        value: ob.id, name: ob.id
                    }
                });
                setUploadScheme(record)
            } else {
                setUploadScheme([]);
            }

        }).catch(err => setUploadScheme([]));
    }
 

    /**
     * GET REPORTING MANAGERS BASED ON CHILD COMPONENT DATA CHANGE
     * @param {*} params 
     */
    const getReportingManagers = (params) => {
        if (props.searchFilters.reporting_role && params.month) {

            getReportingManagerName(props.searchFilters.reporting_role, new Date(params.month));
        }
    }

    /**
     * GET REPORTING MANAGERS LISTING
     * @param {*} reporting_name 
     * @param {*} month 
     */
    const getReportingManagerName = (reporting_name, month) => {

        setReportManagerOption([]);

        getReportingManagerList(reporting_name, month);

    }

    const getReportingManagerList = (reporting_name, month) => {
        let role_id = ROLE_LABEL_ID[reporting_name];

        dispatch(CommonActions.getReportingManager({ role_id: role_id, month: month })).then((res) => {
            let managerList = (res && res.data && res.data.user_list) || [];
            setReportManagerOption(managerList);
        });
    }

    const searchFilter = () => { 
        dispatch(CommonActions.checkedAllStatus(false));
        dispatch(CommonActions.updateApproveButtonStatus(false));
        dispatch(CommonActions.getMultiSelectedLoans([]));
        dispatch(CommonActions.setExcludeMultiSelectedSfa([]));
       
        if(Object.keys(selectedFilters).length){ 
            props.getDashboardList({...selectedFilters, page_no: 1}) 
            
        }else{
            toast.error("Please select any filter")
        }
    } 
   
    const resetFilter = async () => {
        dispatch(CommonActions.checkedAllStatus(false));
        dispatch(CommonActions.updateApproveButtonStatus(false));
        dispatch(CommonActions.getMultiSelectedLoans([]));
        dispatch(CommonActions.setExcludeMultiSelectedSfa([]));
        if(props.resetSearchFilter){
            props.resetSearchFilter();
        } 
        resetFilters()

    }
 
    return (
        <React.Fragment>
            <div className="lead-list-filter">
                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            value={
                                (selectedFilters.role_id)
                                    ? roleOption.filter(
                                        (role) => (+role.sfa_role_id === +selectedFilters.role_id)
                                    )
                                    : (roleName ? roleOption.filter((role) => role.name.toLowerCase() === roleName) : [])
                            }
                            onChange={(val) => changeHandler(val, "role_id")}
                            options={roleOption}
                            placeholder="Role"
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            isClearable={true}
                        />
                    </div>
                </fieldset>

                <fieldset className="single-select">  
                    <div className="material">
                        <MultiSelect
                            isMulti={true}
                            options={incentiveNameOption}
                            placeholder="Incentive Name"
                            iconAfter="false"
                            value={
                                selectedFilters.scheme_type_id
                                    ? incentiveNameOption.filter((incentive) =>
                                        selectedFilters.scheme_type_id.includes(incentive.value)
                                    )
                                     : []
                            }
                            onChange={(val, event) =>
                                changeHandler(val, "scheme_type_id", event)
                            }
                            className="react-select"
                            classNamePrefix="react-select" 
                        /> 

                    </div>
                </fieldset>

                <fieldset className="dateragefiled">
                    <div className="material">
                        <MonthYear
                            updateFilters={updateFilters}
                            selectedFilters={selectedFilters}
                            setSearchFilters={setSelectedFilters}
                            getUploadedDocs={getUploadedDocs}
                            searchFilters={props.searchFilters}
                            action={Object.keys(selectedFilters).length ? props.action : ''}
                            resetStateFilter={resetStateFilter}
                            getReportingManagers={getReportingManagers}

                        />
                    </div>
                </fieldset>

                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            value={
                                selectedFilters.reporting_role
                                    ? reportingRoleList.filter(
                                        (ob) =>
                                            ob.name.toLowerCase() === selectedFilters.reporting_role.toLowerCase()
                                    )
                                    : []
                            }
                            onChange={(val) => changeHandler(val, "reporting_role")}
                            options={reportingRoleList}
                            placeholder="Reporting to Role"
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            isClearable={true}
                        />
                    </div>
                </fieldset>

                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            value={
                                selectedFilters.reporting_id
                                    ? reportManagerOption.filter(
                                        (e) =>
                                            +e.value ===
                                            +selectedFilters.reporting_id
                                    )
                                    : []
                            }
                            onChange={(val) => changeHandler(val, "reporting_id")}
                            options={reportManagerOption}
                            placeholder="Reportee Manager"
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            isClearable={true}
                        />
                    </div>
                </fieldset>
   
                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            value={
                                selectedFilters.upload_csv
                                    ? uploadScheme.filter(
                                        (ob) => +ob.value === +selectedFilters.upload_csv)
                                    : []
                            }
                            onChange={(val) => changeHandler(val, "upload_csv")}
                            options={uploadScheme}
                            placeholder="Uploaded Schemes"
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            isClearable={true}
                        />
                    </div>
                </fieldset>
               
                <div className="btn-submit-reset top-btn-none">
                    <button className="btn-primary" onClick={searchFilter}>Search</button>
                    <button className="btn-reset" style={{ cursor: 'pointer' }} onClick={resetFilter}>Reset</button>
                </div>
 
            </div>
        </React.Fragment>
    );
};
export default LeadListFilter;
