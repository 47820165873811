import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; 
import FullPageLoader from "../../common/FullPageLoader"; 
import CustomValueContainer from "../../common/CustomValueContainer"; 
import Select from "react-select"; 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddTargetData from "./AddTargetData";
import Pagination from "../../common/Pagination";
import incentive_img from "../../../webroot/images/incentive_scheme_img.svg"; 





const TargetListTable = (props) => {
    const {targetSampleFile, roleList} = props;  
    const { t } = useTranslation("common");
    const [loading, setLoading] = useState(false);  
    const [paginationData, setPaginationData] = useState({});
    const [targetDetails, setTargetDetails] = useState([]);
    const [searchFilters, setSearchFilters] = useState(props.targetFilters || {});
    const [startDate, setStartDate] = useState(new Date());
    const [refresh, setRefresh] = useState(0);  
    const [tableHeaders, setTableHeaders] = useState([]);
    const [bulkUploadPopUp, setBulkUploadPopUp] = useState(false);
    const [addTargetPopup, setAddTargetPopup] = useState(false); 

    useEffect(() => {
        setTargetDetails(props.targetList.data?.data);
        setTableHeaders(props.targetList.data?.headers);
        setPaginationData(props.targetList.pagination) 
    }, [props.targetList])
  

    const handleRequestData = (params) => {
         props.getTargetListData(params); 
    };

    const runPaginationFunc = () => { };

    const changeHandler = (value, name) => {
        let selectedFiltersObj = searchFilters;   
        if(name === 'month'){ 
            value = new Date(value) ;
            value.setTime( value.getTime() - new Date().getTimezoneOffset()*60*1000 ); 
            setStartDate(value);
            selectedFiltersObj[name] = new Date(value).toISOString()?.split('T')[0]; 
        } else {
            selectedFiltersObj[name] = value?.value; 
        }
        setSearchFilters(selectedFiltersObj);
        props.setTargetFilters(selectedFiltersObj);
        setRefresh(refresh+1);
    };

    const getFilteredList = () => {
        props.getTargetListData(searchFilters); 
    };


    const showModalBulkUploadPopUp = () => {
        setBulkUploadPopUp(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideModalBulkUploadPopUp = () => {
        setBulkUploadPopUp(false)
        document.body.classList.remove("overflow-hidden");
        let params = props.targetFilters;
        props.getTargetListData(params);
    }
 
     
    let BusinessLine = [{value : "IF",  label : "IF"} ]; 
     return (
        <React.Fragment>
            <FullPageLoader show={loading} />
            <div className="active-scheme-outer targer-scheme-outer">
            <div className="lead-list-filter dealer-filters p-md-b">
                <div className="dealer-filter-list"> 
                <fieldset className="dateragefiled">
                    <div className="material">

                        <div className="datepicker">
                            <DatePicker
                                selected={startDate ? new Date(startDate) : null}
                                onChange={(date) => {changeHandler(date, "month")} }
                                dateFormat="MM/yyyy"
                                placeholderText="Month"
                                showMonthYearPicker
                                maxDate={new Date()}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}

                            />
                        </div>
                    </div>
                </fieldset>

                <fieldset className="single-select">
                    <div className="material">
                        <Select
                        components={{ ValueContainer: CustomValueContainer }}
                            value={  (searchFilters.business_line)
                                ? BusinessLine.filter(
                                    (product) => (product.label === searchFilters.business_line)
                                )
                                : null}
                            onChange={(val) => {changeHandler(val, "business_line")}}
                            options={BusinessLine}
                            placeholder="Business Line"
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            isClearable={true}
                        />
                    </div>
                </fieldset>

                <fieldset className="single-select">
                    <div className="material">
                        <Select
                        components={{ ValueContainer: CustomValueContainer }}
                            value={  (searchFilters.role_id)
                                ? roleList.filter(
                                    (role) => (role.value === searchFilters.role_id)
                                )
                                : null}
                            onChange={(val) => {changeHandler(val, "role_id")}}
                            options={roleList}
                            placeholder="Role"
                            className="react-select"
                            classNamePrefix="react-select"
                            isSearchable={false}
                            isClearable={true}
                        />
                    </div>
                </fieldset>

                <div className="btn-search-reset">
                    <button className="btn-primary" onClick={() => {getFilteredList()}}>Submit</button> 
                </div>
                </div>
                <div className="d-flex btn-calculate-upload"> 
                <button className="btn-line btn-export m-md-l" onClick={showModalBulkUploadPopUp}> + Add Target</button>
               
                </div> 
              
            </div>
               { targetDetails?.length ?  <div className="p-lg-t">
                    <div className="sub-heading p-lg-b ">
                        <h2>User Level Targets ({targetDetails?.length})</h2>
                    </div> 

                    <div>
                        <FullPageLoader show={loading} />
                        <div className="">
                            <div className="data-table bg-white">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>SFA ID</th>
                                            <th>User Name</th>
                                            <th>Month</th>
                                            <th>Business Line</th>
                                            <th>Role</th>
                                            <th>Disbursal Target</th>
                                            <th>{"Onboard < 200"}</th>
                                            <th>{"Onboard >= 200"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            targetDetails?.length ?
                                                targetDetails.map((target, index) => ( 
                                                        <tr className={"active"} key={index}> 
                                                            <td>{target.user_id}</td> 
                                                            <td> <span className="truncate" title={target.name}>{target.name}</span></td>
                                                            <td>{target.month}</td>
                                                            <td>{target.product_type}</td>
                                                            <td>{target.role}</td>
                                                            <td>{target.disbursal_target}</td>
                                                            <td>{target.onboard_1}</td>
                                                            <td>{target.onboard_2}</td> 
                                                        </tr> 
                                                ))
                                                : (loading) ? <tr><td>&nbsp;</td></tr> : <tr><td colSpan={6 + tableHeaders?.length} className="no-data"> {t('DEALER.NO_DATA_FOUND')}</td></tr>

                                        }
                                    </tbody> 
                                </table> 
                            </div>

                             <div className="pagination-outer">
                                 {!loading && paginationData && paginationData.total ? <Pagination
                                     paginateData={{
                                         page: 1,
                                         runPaginationFunc,
                                         loading: loading,
                                         tableData: [],
                                         totalLengthWithoutPagination: 0,
                                         paginationData,
                                         filterData: searchFilters,
                                     }}
                                     action={"payoutDetails"}
                                     sendRequestDataToParent={handleRequestData}
                                 /> : ''}
                             </div>
                        </div> 
                    </div>
                </div>
                :  
                <div className="active-scheme-outer">
                <div className="white-bg">
                    <img src={incentive_img} className="" alt="" />
                    <span>{t('COMMON.NO_TARGET_LIST')}</span>
                    <button className="btn-primary"  onClick={showModalBulkUploadPopUp}>
                        {t('FORM.ADD_TARGET')}
                    </button>
                </div>
            </div>
                }

              {bulkUploadPopUp && <AddTargetData month={startDate} sampleFile={targetSampleFile} bulkUploadPopUp={bulkUploadPopUp} handleClose={hideModalBulkUploadPopUp}/> }            </div>
        </React.Fragment>
    );
};

export default TargetListTable;
