import Modal from "../../common/Modal";
import { useTranslation } from 'react-i18next';
import FileUpload from "./FileUpload"
import { useEffect, useState } from "react";
import BulkHistoryTable from "./BulkHistoryTable";
import PpdDeviation from "./PpdDeviation";


function BulkUploader(props) {
    const { t } = useTranslation('common');

    const [fileUplodValue, setBulkUpload] = useState({
        bulkUpload: false,
        bulkUploadStatus: false,
        bulkUploadMsg: false
    })
    const [bulkUploadPopUp, setBulkUploadPopUp] = useState(false);
    const [fileUploadType, setBulkUploadType] = useState('');

    useEffect(()=>{
        //SHOW POPUP IF OPENED
        if(props.handleBulkUploader && props.handleBulkUploader.bulkUpload){
            setBulkUpload({
                ...fileUplodValue, bulkUpload: true,type:1
            })
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.handleBulkUploader])

    /**
     * UPLOAD POPUP SHOW/HIDE HANDLER
     * @param {*} params 
     */
    const showModalBulkUpload = (params,type) => {
        setBulkUploadPopUp(false)
        document.body.classList.remove("overflow-hidden");

        if(params.bulkUpload){
            document.body.classList.add("overflow-hidden");
        }
        setBulkUploadType(type);
        setBulkUpload({
            ...fileUplodValue, ...params
        })
    }

    /**
     * BULK UPLOAD STATUS HANDLER
     * @param {*} params 
     */
    const uploadStatusHandler = (params) => {
        setBulkUpload({
            ...fileUplodValue, ...params
        })
    }

    const showModalBulkUploadPopUp = () => {
        setBulkUploadPopUp(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideModalBulkUploadPopUp = () => {
        setBulkUploadPopUp(false)
        document.body.classList.remove("overflow-hidden");
    }

    return (

        <>
            <button className="btn-line btn-export m-md-l" onClick={showModalBulkUploadPopUp}> <i className="ic-upload"></i> {t('COMMON.BULK_UPLOAD_HISTORY')}</button>
            <div className={(fileUploadType===3)? "model-popup-outer ppd-devation-popup":"model-popup-outer bulk-upload-history-popup"}>
                <Modal show={fileUplodValue.bulkUpload} handleClose={()=>showModalBulkUpload({bulkUpload: false, bulkUploadStatus: false, bulkUploadMsg: false})} >
                    <div className="modal-header">
                      {
                        (fileUploadType===3)? <h2>{t('BULK_UPLOAD_DETAIL.PPD_HEAD')}</h2>:''
                      }
                    </div>
                    <div className="modal-body">
                        {
                          (fileUploadType===3)?
                                <PpdDeviation month={props.month} /> 
                            : 
                            <div className='bulk-upload-history-content'>
                                <div className='bulk-upload-sec'>
                                    <h3>{(fileUploadType===1)?t('BULK_UPLOAD_DETAIL.BULK_UPLOAD_DATA_1'):t('BULK_UPLOAD_DETAIL.BULK_UPLOAD_DATA_2')}</h3>
                                    <FileUpload uploadStatusHandler={uploadStatusHandler} fileUplodValue={fileUplodValue} fileUploadType={fileUploadType} />

                                </div>
                                <BulkHistoryTable  fileUploadType={fileUploadType} />
                            </div> 
                        }
                        
                    </div>
                    <div className="modal-footer">
                        <div className="download-txt">
                            Download input format < a href={props.BulkuploadUrl[fileUploadType]}>
                                Click Here
                            </a>
                        </div>
                    </div>
                </Modal>
            </div>
            <div className="model-popup-outer confirmation-popup">
            <Modal show={bulkUploadPopUp} handleClose={()=>hideModalBulkUploadPopUp()} >
                <div className="modal-header">
                    <h2>{t('BULK_UPLOAD_DETAIL.BULK_UPLOAD_HEAD')}</h2>
                </div>
                <div className="modal-body" >
                    <div className='confirmation-btn bulkupload'>
                        <button className='btn-primary'  onClick={()=>showModalBulkUpload({bulkUpload: true},1)}>{t('BULK_UPLOAD_DETAIL.SCHEME_LEVEL_HEAD')}
                        </button>
                        <button className='btn-primary'  onClick={()=>showModalBulkUpload({bulkUpload: true},2)}>{t('BULK_UPLOAD_DETAIL.USER_LEVEL_HEAD')}</button>
                        <button className='btn-primary'  onClick={()=>showModalBulkUpload({bulkUpload: true},3)}>{t('BULK_UPLOAD_DETAIL.PPD_HEAD')}</button>
                       
                    </div>
                </div>
            </Modal>
        </div>
        </>
        
  );
}

export default BulkUploader;