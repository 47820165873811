import React, { useEffect,useState} from 'react'
import Modal from "../../common/Modal";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next"; 
import { CommonActions } from "../../../store/action"; 
import FullPageLoader from '../../common/FullPageLoader'; 

function AgentPointExport(props) { 
    const [buttonDisable, setButtonDisable] = useState(true);
    const [leadData, setLeadData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation("common"); 
    
    const headers = [
        { label: t('AGENT_POINT_CONFIG.SFA_ID'), key: "agent_id" }, 
        { label: t('AGENT_POINT_CONFIG.TOTAL_POINTS'), key: "total_points" },
        { label: t('AGENT_POINT_CONFIG.REEDEEMABLE_POINTS'), key: "redeem_points" },
        { label: t('AGENT_POINT_CONFIG.CURRENT_POINTS'), key: "current_points" }, 
    ];

    const leadHeaders = [
        { label: t('AGENT_POINT_CONFIG.LOAN_ID'), key: "lead_id" }, 
        { label: t('AGENT_POINT_CONFIG.DISB_AMOUNT'), key: "disbursed_amount" },
        { label: t('AGENT_POINT_CONFIG.MULTIPLIER'), key: "multiplier" },
        { label: t('AGENT_POINT_CONFIG.CURRENT_POINTS'), key: "current_points" }, 
        { label: t('AGENT_POINT_CONFIG.REEDEEMABLE_POINTS'), key: "redeem_points" },
    ];
  
    
 
    useEffect(() => {  
        exportDealerdata();
        // eslint-disable-next-line
    },[])

    const exportDealerdata = () => {
        let params= {};
        if(props.agent_id){
            params["agent_id"] = props.agent_id;
        }
        setLoading(true); 
        CommonActions.getAgentPointDetails(params).then(res => {
            setLoading(false);  
            if (res.status === 200 && res?.data?.length) {
                setButtonDisable(false);
                let totalData = [];
                if(props.agent_id){
                    if(res.data.length){
                        for(let item of res.data[0].lead_list){
                            totalData.push({
                                lead_id: item.lead_id,
                                disbursed_amount: item.disbursed_amount,
                                multiplier: item.multiplier,
                                current_points: item.current_points,
                                redeem_points : res.data[0].redeem_points
                            })
                        } 
                    } 
                }else{
                    totalData = res.data?.map(item => ({
                        ...item, 
                        current_points : (+item.total_points - +item.redeem_points)?.toFixed(2)
                    })); 
                }
               
                setLeadData(totalData);
            } else {
                setLeadData([]); 
            }
        }).catch(function () {
            setLoading(false);
        });
    };
    let mainHeader = (props.agent_id) ? leadHeaders : headers;
    return (
        <div className="model-popup-outer confirmation-popup">
            <FullPageLoader show={loading} />
            <Modal show={props.show} handleClose={props.handleClose} >
                <div className="modal-header">
                    <h2>{t('DETAIL.DATA_EXPORT_AS')}</h2>
                </div>
                <div className="modal-body" >
                    <div className='confirmation-btn'>
                        <CSVLink data={leadData} headers={mainHeader} filename={"lead-data.csv"}>
                            <button className='btn-primary' disabled={buttonDisable}>{t('AGENT_POINT_CONFIG.AGENT_POINT_DATA')}</button>
                        </CSVLink> 
                        
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AgentPointExport