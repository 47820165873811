import React, {useState} from 'react';
import Select from 'react-select'; 
import InputField from '../../common/InputField';
import { useTranslation } from "react-i18next";
import { BiPlusCircle, BiMinusCircle }   from "react-icons/bi";
import CustomValueContainer from '../../common/CustomValueContainer' ;
import { Calendra,IfDate } from '../../cards/DatePicker'
import { DateFormat } from "../../../config/helper";


const IfDisbursalCard = (props) => {
    const {element,tempErrors,element_index,element_key,minMaxDate} = props;
    const {scheme_duration} = props?.incentive_config
    const {operator_array} = props.incentive_config
    const { t } = useTranslation("common");
    const [isPercentage, setIsPercentage] = useState(1);
    
    const onChange = (event) => {
        let { name, value } = event.target;
        const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/); 
        if(validated){
            props.onChange(name, value, '', props.element)
        } 
    }

    const onChangeMinMax = (event) => {
        let { name, value } = event.target;
        const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/); 
        if(validated && value <= 999){
            props.onChange(name, value, '', props.element)
        } 
    }
    const addMore = (e,index,type)=>{ 
        let addStatus=true;
        if(element.form_config.ACTIVATED_ONBOARD_CARD && element[element_key].length>3){
            addStatus=false;
        }
        if(addStatus){
            e.stopPropagation(); 
            let params = {
                key: index+1,
                index :props.element_index,
                type:type,
                scheme:props.element.scheme_type_id
            } 
            props.addMoreIncentive(params)
        }
    }
    const removeMore = (e,index,data,type)=>{  
        e.stopPropagation();
        let params = {
            key: index,
            index : props.element_index,
            type:type,
            scheme: props.element.scheme_type_id,
            is_deleted : (data.id)? data.id : false
        }  
        props.removeMoreIncentive(params)
    }
    const operatorChange = (name,value) => {
        props.onChange(name, value.value, '', props.element)
    }

    const onChangeDate = (name,date)=>{
        props.onChange(name, DateFormat(date), '', props.element)
    } 
    const minDate = minMaxDate?.minDate ? minMaxDate?.minDate : scheme_duration?.find(ele => ele.value == element?.scheme_duration)?.start;
    const maxDate = minMaxDate?.maxDate ? minMaxDate?.maxDate : scheme_duration?.find(ele => ele.value == element?.scheme_duration)?.end;
    
    return (
        <React.Fragment>
             <div className='extra-incentive-outer add-incentive-table'> 
                <table>
                    <thead>
                        <tr>
                            {
                              (element.form_config.FRESH_DISBURSAL_CARD || element.form_config.DEALER_ACTIVATED_CARD || element.form_config.RENEWAL_REPAYMENT_CARD ) && 
                              <>
                                <th>{t('IF.FORM.MIN_DISB_ACH')}
                                <span>{(element.form_config.FRESH_DISBURSAL_CARD)?t('IF.FORM.ACH'):(element.form_config.DEALER_ACTIVATED_CARD)?t('IF.FORM.DEALER_ACTIVE'):t('IF.FORM.RENEWAL')}</span>
                                </th>
                                <th>{t('IF.FORM.MAX_DISB_ACH')}
                                <span>{(element.form_config.FRESH_DISBURSAL_CARD)?t('IF.FORM.ACH'):(element.form_config.DEALER_ACTIVATED_CARD)?t('IF.FORM.DEALER_ACTIVE'):t('IF.FORM.RENEWAL')}</span>
                                </th>
                              </>
                            }
                            {
                              (element.form_config.ACTIVATED_ONBOARD_CARD) && 
                              <>
                                <th>{t('IF.FORM.OPERATOR')}
                                <span>{t('IF.FORM.OP_SPAN')}</span>
                                </th>
                                <th>{t('IF.FORM.DEALER_OB')}
                                <span>{t('IF.FORM.ONBOARD_SPAN')}</span>
                                </th>
                              </>
                            }
                            {
                              (element.form_config.ADDITION_INC_CARD) && 
                              <>
                                <th>{t('IF.FORM.TARGET_ACH')}
                                <span>{t('IF.FORM.ACH')}</span>
                                </th>
                                <th>{t('IF.FORM.DATE')}
                                <span>{t('IF.FORM.DATE_SPAN')}</span>
                                </th>
                              </>
                            }
                          
                            <th>{t('IF.FORM.INC_AMT')}
                            <span> {t('FORM.PER_MONTH')}</span>
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {element && element[element_key] && element[element_key].length > 0 && element[element_key].map((data, index) => (
                            <tr key={(element.id)?element.id+'_'+element_index+'_'+index: element_index+'_'+index}>
                                <td>
                                    {
                                        element.form_config.MIN_DISBURSAL &&  
                                        <> 
                                            <fieldset className="m-sm-r">
                                                <div className="material">
                                                    <InputField
                                                        inputProps={{
                                                            id: "min_disb_per_" + index,
                                                            type: "text",
                                                            name: "min_disb_per_" + index,
                                                            placeholder: " ",
                                                            value:data.min_disb_per || '',
                                                            className: "form-input",
                                                            disabled: element.is_expired || false
                                                        }}
                                                        onChange={(e, i, q, u) => onChangeMinMax(e, i, q, u)}
                                                    />
                                                </div>
                                                {
                                                    (tempErrors && tempErrors["min_disb_per_" + index]) ? <span className="error show">{tempErrors["min_disb_per_" + index]}</span> : <span>&nbsp;</span>
                                                }
                                            </fieldset>
                                        </>
                                    }
                                    {
                                        element.form_config.OPERATOR &&  
                                        <> 
                                            <fieldset className="single-select m-sm-r operator">
                                                <div className="material">
                                                    <Select components={{ ValueContainer: CustomValueContainer }}
                                                        value={(data.operator) ? operator_array.filter(({ value }) => value === data.operator) : null}
                                                        onChange={(value)=>{operatorChange( "operator_" + index,value)}}
                                                        options={operator_array}
                                                        isDisabled={element.is_expired}
                                                        placeholder={''}
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        isSearchable={false}
                                                    />
                                                </div>
                                                {
                                                (tempErrors && tempErrors["operator_" + index]) ? <span className="error show">{tempErrors["operator_" + index]}</span> : <span>&nbsp;</span>
                                                }
                                                    
                                            </fieldset>
                                        </>
                                    }
                                    {
                                        element.form_config.TARGET &&
                                        <>
                                        <fieldset className="m-sm-r">
                                            <div className="material">
                                                <InputField
                                                    inputProps={{
                                                        id: "target_amount_" + index,
                                                        type: "text",
                                                        name: "target_amount_" + index,
                                                        placeholder: " ",
                                                        value:data.target_amount || '',
                                                        className: "form-input",
                                                        disabled: element.is_expired || false,
                                                        percentage: isPercentage
                                                    }}
                                                    onChange={(e, i, q, u) => onChange(e, i, q, u)}
                                                />
                                            </div>
                                            {
                                                (tempErrors && tempErrors["target_amount_" + index]) ? <span className="error show">{tempErrors["target_amount_" + index]}</span> : <span>&nbsp;</span>
                                            }
                                        </fieldset>
                                        </>
                                    }
                                </td>
                                <td>
                                    {
                                        element.form_config.MAX_DISBURSAL && 
                                        <>
                                            <fieldset className="m-sm-r">
                                                <div className="material">
                                                    <InputField
                                                        inputProps={{
                                                            id: "max_disb_per_" + index,
                                                            type: "text",
                                                            name: "max_disb_per_" + index,
                                                            placeholder: " ",
                                                            value:data.max_disb_per || '',
                                                            className: "form-input",
                                                            disabled: element.is_expired || false
                                                        }}
                                                        onChange={(e, i, q, u) => onChangeMinMax(e, i, q, u)}
                                                    />
                                                </div>
                                                {
                                                    (tempErrors && tempErrors["max_disb_per_" + index]) ? <span className="error show">{tempErrors["max_disb_per_" + index]}</span> : <span>&nbsp;</span>
                                                }
                                            </fieldset>
                                        </>
                                    }
                                    {
                                        element.form_config.ONBOARD && 
                                        <>
                                            <fieldset className="m-sm-r">
                                                <div className="material">
                                                    <InputField
                                                        inputProps={{
                                                            id: "onboard_limit_" + index,
                                                            type: "text",
                                                            name: "onboard_limit_" + index,
                                                            placeholder: " ",
                                                            value:data.onboard_limit || '',
                                                            className: "form-input",
                                                            disabled: element.is_expired || false
                                                        }}
                                                        onChange={(e, i, q, u) => onChange(e, i, q, u)}
                                                    />
                                                </div>
                                                {
                                                    (tempErrors && tempErrors["onboard_limit_" + index]) ? <span className="error show">{tempErrors["onboard_limit_" + index]}</span> : <span>&nbsp;</span>
                                                }
                                            </fieldset>
                                        </>
                                        
                                    }
                                    {
                                        element.form_config.IF_DATE && 
                                        <>
                                        <fieldset className="m-sm-r ifDate">
                                            <IfDate 
                                                element={element} 
                                                dateFormat="dd-MM-yyyy" 
                                                label=""
                                                onChange={(e) => {onChangeDate("if_date_"+index,e) }} 
                                                minDate={new Date(minDate)}
                                                maxDate={new Date(maxDate)}
                                                selected={data.if_date || null} />
                                            
                                            {
                                                (tempErrors && tempErrors["if_date_" + index]) ? <span className="error show">{tempErrors["if_date_" + index]}</span> : <span>&nbsp;</span>
                                            }
                                        </fieldset> 
                                        </>
                                    }

                                </td>
                                <td>
                                    <fieldset>
                                        <div className="material m-sm-r">
                                            <InputField
                                                inputProps={{
                                                    id: "incentive_amount_" + index,
                                                    type: "text",
                                                    name: "incentive_amount_" + index,
                                                    placeholder: " ",
                                                    value:data.incentive_amount || '',
                                                    className: "form-input",
                                                    disabled: element.is_expired || false
                                                }}
                                                onChange={(e, i, q, u) => onChange(e, i, q, u)}
                                            />
                                        </div>
                                        {
                                            (tempErrors && tempErrors["incentive_amount_" + index]) ? <span className="error show">{tempErrors["incentive_amount_" + index]}</span> : <span>&nbsp;</span>
                                        }
                                    </fieldset>
                                </td>
                                {   
                                    element[element_key] && (
                                        (index ===0)?
                                        <td>
                                         {
                                           (element.is_expired)?<span className="add-minus"><BiPlusCircle size="1.4em"/></span>:<span className="add-minus" onClick={(e)=>addMore(e,index,element_key)}><BiPlusCircle size="1.4em"/> </span>
                                         }   
                                        
                                        </td>: 
                                        <td>
                                            {
                                               (element.is_expired)?<span className="add-minus"> <BiMinusCircle size="1.4em"/></span>: <span className="add-minus" onClick={(e)=>removeMore(e,index,data,element_key)}> <BiMinusCircle size="1.4em"/></span>
                                            }
                                        
                                    </td>
                                    ) 
                                }
                                
                            </tr>
                        ))}

                    </tbody>
                </table>



                </div>
        </React.Fragment>
    );

}
export default IfDisbursalCard;