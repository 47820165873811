import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MonthYear = (props) => {  
    const [startDate, setStartDate] = useState(props?.searchFilters?.incentive_month || new Date());
 
 
    useEffect(()=>{ 
        if(props.searchFilters.incentive_month){
            changeHandler(props.searchFilters.incentive_month);
        } 
 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.action])


    useEffect(()=>{
        if( props.searchFilters.incentive_month && props.resetStateFilter){
            setStartDate(props.searchFilters.incentive_month);
            props.getUploadedDocs(new Date(props.searchFilters.incentive_month))

        }
 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.resetStateFilter])


    const changeHandler = (date, resetFlag=false) => {
        let selectedFiltersObj = props.selectedFilters;
    
        if(!resetFlag){
            //RESET DATE DEPENDANT KEYS FROM FILTERS
            delete selectedFiltersObj['upload_csv'];
            delete selectedFiltersObj['reporting_id'];
        }
        
        /**EXPERIMENTAL CODE */
        date = new Date(date) ;
        date.setTime( date.getTime() - new Date().getTimezoneOffset()*60*1000 );
        /**EXPERIMENTAL CODE END*/
        
        setStartDate(date);

        props.updateFilters({ ...selectedFiltersObj, "incentive_month": new Date(date).toISOString() })
        props.getUploadedDocs(new Date(date))
        props.getReportingManagers({month: new Date(date)})

    }
    
    return (
        <div className="datepicker">
            <DatePicker
                selected={startDate ? new Date(startDate) : null}
                onChange={(date) => changeHandler(date)}
                dateFormat="MM/yyyy"
                placeholderText="Month"
                showMonthYearPicker
                maxDate={new Date()}
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
            
            />
        </div>
    );
}



export { MonthYear }